import { useSortable } from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Row from 'react-bootstrap/Row';
import move from './move.png';
import { useState } from "react";
// import Container from 'react-bootstrap/Container';
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
  } from "@dnd-kit/core";
  import {
    SortableContext,
    verticalListSortingStrategy
  } from "@dnd-kit/sortable";
import Grid from '@mui/material/Grid';
import { Switch } from '@mui/material';
import { PopoverPicker } from "../ColorPicker/PopoverPicker";


function SortableItem(props) {
    // props.id
    // JavaScript

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const value_items = props.item
    const ChangeCount = props.ChangeCount
    const ChangeColor = props.ChangeColor
    const ChangeCheck = props.ChangeCheck
    const prefix = props.prefix
    const isSortable = props.isSortable
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    const name = value_items.name

    // const [activeHover, setActiveHover] = useState(true);

    // function handleHover() {
    //     if (isSortable){
    //         setActiveHover(true);
    //     } else {
    //         setActiveHover(false)
    //     }
    // }
    // const listHover = {
    //     backgroundColor: value_items.checked ? value_items.color + "80" : "#00000080", overflow: "unset",
    //     boxShadow: activeHover ? "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset" : "unset",
    //     transform: activeHover ? "translateY(-2px)" : "unset"
    // }
    // value_items.color = value_items.color ? undefined : "#000000"
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
{/* <Card body className="m-3"> */}
{/* <Row key={value_items.name + "_settings"}> */}
    <div className="list__card" 
        style={{backgroundColor: value_items.checked ? value_items.color + "80" : "#00000080", overflow: "unset"}}
        // style={listHover}
        // onMouseEnter={handleHover}
        // onMouseLeave={handleHover}
    >
    <Grid container spacing={0} style={{ alignItems: "center" }}>
        <Grid item={true} xs={4} key={prefix + "_" + value_items.name + "_name"}>{value_items.name}</Grid>
        <Grid item={true} xs={3} key={prefix + "_" + value_items.name + "_number"}>
            <input
                type="number"
                key={prefix + "_" + value_items.name + "john"}
                name={value_items.name} 
                value={value_items.current} 
                pattern="[0-9]*"
                min="0" 
                max={value_items.count} 
                style={{width:"55px"}}
                onChange={(value) => ChangeCount(value)}>
            </input>
        </Grid>
        <Grid item={true} xs={2} key={prefix + "_" + value_items.name + "_color"}>
            <PopoverPicker
                type="color"
                key={prefix + "_" + value_items.name + "_color"}
                name={value_items.name}
                color={value_items.color}
                onChange={ChangeColor}
                setIsSortable={props.setIsSortable}
            /> 
            
        </Grid>
        <Grid item={true} xs={2} style={{width:"10px"}} key={value_items.name + "_checked"}>
            {/* <input type="checkbox"></input> */}
            {/* <Switch {...label} defaultChecked /> */}
            <Switch 
                size="small"
                key={prefix + "_" + value_items.name + "_check"} 
                category={name} 
                checked={value_items.checked}
                onChange={(category, color) => ChangeCheck(color, name)}
            />
        </Grid>
        <Grid item={true} xs={1} style={{textAlign: "right"}}>
            <img src={move}></img>
        </Grid>
    </Grid>
</div>
{/* </Row> */}
{/* </Card> */}
</div>
    )
};


export function CategorySettings(props) {
    const extraPrefix = props.extraPrefix
    const handleDragEnd = props.handleDragEnd
    const isSortable = props.isSortable
    const setIsSortable = props.setIsSortable
    // Array.from(languagesObject.languages)
    const sensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
        // useSensor(KeyboardSensor, {
        //   coordinateGetter: sortableKeyboardCoordinates,
        // })
      );
    const items = props.categories
    return (
        <Grid container key={"sortablegrid" + extraPrefix} spacing={1} fontSize="14px">
            <Grid item={true} xs={4} marginBottom="10px">Aktivitet</Grid>
            <Grid item={true} xs={2.5} marginBottom="10px">Antal</Grid>
            <Grid item={true} xs={2.3} marginBottom="10px">Farve</Grid>
            <Grid item={true} xs={2} marginBottom="10px">Inkl.</Grid>
            <Grid item={true} xs={12}>
            <DndContext
                key={"dndcontext" + extraPrefix}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                sensors={sensors}
                >
                    {/* <Container> */}

                    <SortableContext
                        key={"sortablecontext" + extraPrefix}
                        items={items}
                        strategy={verticalListSortingStrategy}
                        disabled={!isSortable}
                    >
                    {/* We need components that use the useSortable hook */}
                    {items.map(category => <SortableItem 
                                                // disable={true}
                                                key={"sort_" + extraPrefix + "_" + category.name} 
                                                id={category.id} 
                                                name={category.name} 
                                                item={category} 
                                                prefix={extraPrefix}
                                                ChangeCount={props.ChangeCount} 
                                                ChangeColor={props.ChangeColor}
                                                ChangeCheck={props.ChangeCheck}
                                                setIsSortable={setIsSortable}
                                                isSortable={isSortable}
                                            />)}
                    </SortableContext>
                {/* </Container> */}
            </DndContext>
             </Grid>
        </Grid>
    )
};


