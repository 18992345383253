import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import React, { Fragment } from 'react'
import Home from './pages/home'
import Studio from './pages/studio'
import Checkout from "./pages/checkout";
import FAQ from "./pages/faq";
import { CartProvider } from "react-use-cart"
import './assets/main.css'
import Footer from './components/Footer/footer'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from './components/Header/header.js'
import Grid from '@mui/material/Grid';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { StyledEngineProvider } from "@mui/material";


const theme = createTheme({
    palette: {
        primary: {
          main: '#0077C2', // Replace with your desired color
        },
        grey: {
          200: '#F5F5F5',
          300: '#E0E0E0',
        },
        text: {
          secondary: '#666666',
        },
      },
});


function App() {
    document.title = "Print-lap | Personlig Strava Plakat";

    if (!('indexedDB' in window)) {
        console.log("This browser doesn't support IndexedDB");
        return;
      } else {
        console.log("that's good enough")
      }
    return (
        <div className="App">
            <ErrorBoundary>
                <CartProvider>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <BrowserRouter>
                            {/* <Switch> */}
                                <Routes>
                                    <Route path="/" element={<Home />}/>
                                    <Route path="/studio" element={<Studio />}/>
                                    <Route path="/checkout" element={<Checkout />}/>
                                    <Route path="/checkout/betal" element={<Checkout />}/>
                                    <Route path="/checkout/ordre" element={<Checkout />}/>
                                    <Route path="/faq" element={<FAQ/>}/>
                                    <Route path="*" element={<NotFound/>} />
                                </Routes>
                            {/* </Switch> */}
                        <Footer/>
                        </BrowserRouter>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </CartProvider>
            </ErrorBoundary>
        </div>
    );
}

function NotFound() {


    return (
        <Fragment>
            <Grid container>
                <Grid item={true} xs={12} sm={12}>
                    
                <Header />
                {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri={{ url_for('studio', _external=True)}}&response_type=code&scope=read,read_all,activity:read_all */}
                {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=localhost:3000/studio&response_type=code&scope=read,read_all,activity:read_all */}
                    <Grid container justifyContent="center" spacing={0} marginTop="20px" maxWidth="1200px" marginLeft="auto" marginRight="auto">
                    <Grid item={true} sm={12} xs={0} marginTop="60px"></Grid>
                    <TravelExploreIcon fontSize="large" style={{marginRight: "10px"}}/>
                        <h1>Siden eksisterer ikke</h1>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
        )
  }


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  //   console.log('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <Grid container>
            <Grid item={true} xs={12} sm={12}>
                
            {/* <Header /> */}
            {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri={{ url_for('studio', _external=True)}}&response_type=code&scope=read,read_all,activity:read_all */}
            {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=localhost:3000/studio&response_type=code&scope=read,read_all,activity:read_all */}
                <Grid container justifyContent="center" spacing={0} marginTop="20px" maxWidth="1200px" marginLeft="auto" marginRight="auto">
                <Grid item={true} sm={12} xs={0} marginTop="60px"></Grid>
                    <h1>Hov, noget gik galt.</h1>
                </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )
    }

    return this.props.children;
  }
}

export default App;