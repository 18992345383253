import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import svg from 'svg-crowbar/dist/cjs/svg';
import CloseIcon from '@mui/icons-material/Close';

import { useCart } from 'react-use-cart';

const activityColumns = [
    { field: 'id', headerName: 'id', flex: 1},
    { field: 'image', headerName: 'Lap', flex: 1, sortable: false, renderCell: (params)=>{
        if (params.row.d){
        return (
            <svg width="53px" height="53px" viewBox="0 -53.333333333333336 53.333333333333336 53.333333333333336">
                <path
                    fill="none"
                    d={params.row.d}
                    stroke={params.row.color}
                    strokeWidth="2px"
                />
            </svg>
        )} else {
            return
        }
      }},
    { field: 'distance', headerName: 'Distance', flex: 1, type: 'number'},
    { field: 'date', headerName: 'Dato', flex: 1.5, type: 'date', valueGetter: (params) => {
        return new Date(params.row.date)
    }},
    { field: 'category', headerName: 'Aktivitet', flex: 1.5, type: 'string' },
]


function uniqueArray( ar ) {
    var j = {};
  
    ar.forEach( function(v) {
      j[v+ '::' + typeof v] = v;
    });
  
  
    return Object.keys(j).map(function(v){
      return j[v];
    });
  }

//   uniqueArray(
//     john.map(
//       p => p.john
//     )
//   ).map(category => {
//     return {"john": category, "count": john.filter(con => con.john === category)[0].lars.length}
//   })

function GenerateNewSettings(svgs, oldList){
    let newListData = []
    let order = oldList.map(cat => cat.name)
    order = order.filter(cat => Object.keys(svgs).includes(cat))
    // let categories = uniqueArray(svgs.map(svg => svg.category))
    var index = 1
    for (let category in order){
        let currentSvgs = svgs[order[category]]
        let count = currentSvgs.length
        newListData.push({
            "name": currentSvgs[0]["category"],
            "id": index, //oldList.filter(activity => activity.name === category)[0].id,
            "position": index, //oldList.filter(activity => activity.name === category)[0].position,
            "count": count,
            "current": count,
            "color": currentSvgs[0]["color"],
            "checked": true
        })
        index += 1
    }

    return newListData
}

function DeleteTheSelected(selected){
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    let athlete = JSON.parse(window.localStorage.getItem("athlete_id"))
    const deleteData = async (inputDict) => {
        const response = await fetch(`${API_BASE_URL}/delete_john`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(inputDict)
        })
        const result = await response.json();
      }
    deleteData({"athlete": athlete, "ids": selected})
}


export default function DataTable(props) {
    const setNum = props.setNum
    const deleteData = props.deleteData
    const [openConfig, setOpenConfig] = useState(false)
    let { totalItems } = useCart()
    let useRows = props.useRows;
    const triggerSettingUpdate = props.triggerSettingUpdate
    const setSvgData = props.setSvgData
    const setListData = props.setListData
    const listData = props.listData
    const [selectedRows, setSelectedRows] = useState([]);

    const svgsStoredInLocal = JSON.parse(window.localStorage.getItem("storedSvgs")) ? true : false

    if (Object.keys(useRows).length === 0) {
        return <h2>Intet data</h2>;
    }

    const rowsberows = [];

    for (let name in useRows) {
        for (let j = 0; j < useRows[name].length; j++) {
            let tempSvg = useRows[name][j];
            if (svgsStoredInLocal){
                tempSvg["id"] = tempSvg["indicator"]
            }
            rowsberows.push(tempSvg);
        }
    }

    const handleDelete = () => {

        if (svgsStoredInLocal === false){
            let deleteIndicator = []
            const openRequest = indexedDB.open("poster");
            openRequest.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction("svgs", "readwrite");
                const store = transaction.objectStore("svgs");
                selectedRows.forEach((rowId) => {
                    let john = store.get(Number(rowId))
                    john.onsuccess = () => {
                        deleteIndicator.push(john.result.indicator)
                    }
                    store.delete(Number(rowId));
                });
                
                transaction.oncomplete = function () {
                    console.log("All selected rows have been deleted.");
                    DeleteTheSelected(
                        deleteIndicator
                    )
                    // Refresh the DataTable or remove the selected rows from the state.
                };

                transaction.onerror = function (event) {
                    console.error("Error deleting selected rows: ", event.target.error);
                    // let storedSvgs = window.localStorage.getItem("savedSvgs", JSON.stringify(co))
                };
            };

            const nopenRequest = indexedDB.open("poster");
            nopenRequest.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction("svgs", "readwrite");
                const store = transaction.objectStore("svgs");

                var allRecords = store.getAll()
                allRecords.onsuccess = function() {
                    
                    let storedSvgs = {}
                    let theCategories = []
                    
                    for (let index = 0; index < allRecords.result.length; index++){
                        let svg = allRecords.result[index]
                        if (!theCategories.includes(svg.category)){
                            storedSvgs[svg.category] = []
                            theCategories.push(svg.category)
                        }
                        storedSvgs[svg.category].push(svg)
                    }
                    for (let cat in theCategories){
                        if (storedSvgs[theCategories[cat]]){
                            storedSvgs[theCategories[cat]].sort((a, b) => a.indicator - b.indicator)
                        }
                      }
                    
                    setSvgData(storedSvgs)
                    let newListData = GenerateNewSettings(storedSvgs, listData)
                    // setListData(newListData)
                    // window.localStorage.setItem("listData", JSON.stringify(newListData))
                    triggerSettingUpdate(
                        newListData.map(a => a.count).reduce((a, b) => a + b, 0),
                        newListData.length,
                        newListData,
                    )
                    if (newListData.map(a => a.count).reduce((a, b) => a + b, 0) === 0){
                        deleteData()
                    }
                }
            }
            openRequest.onerror = function (event) {
                console.error("Error opening the database: ", event.target.error);
            };
        } else {
            DeleteTheSelected(
                selectedRows
            )
            let svgs = JSON.parse(window.localStorage.getItem("storedSvgs"))
            svgs = svgs.filter((row) => !selectedRows.includes(row.indicator));
            let categories = {}
            let savedCats = []
            for (let il = 0; il < svgs.length; il++){
                svg = svgs[il]
                if (!savedCats.includes(svg["category"])){
                    savedCats.push(svg["category"])
                    categories[svg["category"]] = []
                }
                categories[svg["category"]].push(svg)
                
            }
            for (let cat in savedCats){
                if (categories[savedCats[cat]]){
                    categories[savedCats[cat]].sort((a, b) => a.indicator - b.indicator)
                }
              }
            setSvgData(categories)
            let newListData = GenerateNewSettings(categories, listData)
            window.localStorage.setItem("storedSvgs", JSON.stringify(svgs))
            // setListData(newListData)
            // window.localStorage.setItem("listData", JSON.stringify(newListData))
            // john.map(a => a.john).reduce((a, b) => a + b, 0)
            triggerSettingUpdate(
                newListData.map(a => a.count).reduce((a, b) => a + b, 0),
                newListData.length,
                newListData,
            )
            if (newListData.map(a => a.count).reduce((a, b) => a + b, 0) === 0){

                deleteData()
            }
        }
        setOpenConfig(false)

    };
    return (
        <>
        <Button onClick={() => setOpenConfig(true)} id={props.id}>Åben konfiguration</Button>
        <Modal
            key="configurationModal"
            id="configurationModal"
            open={openConfig}
            onClose={() => setOpenConfig(false)}
        >
            <Grid container justifyContent="center" marginTop="20px">
                <Grid item={true} xs={12} sm={8}>
                    <div style={{background: "rgba(255,255,255,0.87)", borderRadius: "15px", padding: "20px"}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem', height: "20px" }}>
                            {/* <h2>Data Table</h2> */}
                            {totalItems === 0 && selectedRows.length > 0 && <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                onClick={handleDelete}
                            >
                                Slet valgte aktiviteter
                            </Button>}
                            {totalItems > 0 && selectedRows.length > 0 && <p>
                                Du kan kun fjerne aktiviteter, når kurven er tom. 
                            </p>

                            }
                            {totalItems === 0 && selectedRows.length === 0 && <p>Her kan du fjerne specifikke aktiviteter</p>}
                            <CloseIcon
                                onMouseOver={(e) => {e.target.style.cursor = 'pointer'}}
                                onClick={() => setOpenConfig(false)} style={{float: "right"}}/>
                        </div>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={rowsberows}
                                columns={activityColumns}
                                // pageSize={5}
                                // rowsPerPageOptions={[5]}
                                checkboxSelection
                                onRowSelectionModelChange={(newSelection) => {
                                    setSelectedRows(newSelection);
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Modal>
        </>
    );
}
