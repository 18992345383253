import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Cart } from './cart';
import CloseIcon from '@mui/icons-material/Close';
import './modal.css'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

import { useCart } from 'react-use-cart'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "600px",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  padding: "20px"
};

export default function BasicModal(props) {
  const handleClose = props.handleClose
  const open = props.open
  const setOpen = props.setOpen
  const {totalItems} = useCart();


  const btStyles = {
    button: {
      // backgroundColor: 'rgb(43, 85, 45)',
      background: 'linear-gradient(to right, #80b187, #33a02c)',
      fontFamily: "monFat",
      color: 'white',
      textTransform: 'none',
      borderRadius: '0px',
      height: '40px',
      width: '100px',
      '& .MuiButtonLabel': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        // background: 'linear-gradient(to right, #f22, #ff2, #2f2, #2ff, #22f, #f2f, #f22)',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.7)',

      },
      marginTop: "10px",
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
    },
  };

  return (
    <>
      <Modal
        key="shoppingCartModal"
        id="shoppingCartModal"
        open={open}
        onClose={handleClose}
        aria-labelledby="shopping-cart"
        aria-describedby="shopping-cart-modal-printlap"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item={true} xs={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom="20px" fontFamily="monSemi">
                Indkøbskurv
              </Typography>
            </Grid>
            <Grid item={true} xs={10} textAlign="right">
            <CloseIcon
              onMouseOver={(e) => {e.target.style.cursor = 'pointer'}}
              onClick={() => props.setOpen(false)}
            />
            </Grid>
          </Grid>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}> */}
            {/* {JSON.stringify(window.localStorage.getItem("react-use-cart"))} */}
            <Cart setOpen={props.setOpen} navigate={props.navigate} />
          {/* </Typography> */}
          <div style={{textAlign:"right", marginTop:"20px"}}>
            {(totalItems > 0) && 
              <Link to="/checkout"><Button style={btStyles.button}>Køb nu</Button></Link>
            }
          </div>
        </Box>
      </Modal>
    </>
  );
}
