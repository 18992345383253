import React, { useCallback, useRef, useState } from "react";
import { CompactPicker, GithubPicker, CirclePicker, ChromePicker, SliderPicker, SketchPicker } from "react-color";
import { HexColorPicker } from "react-colorful";
import CloseIcon from '@mui/icons-material/Close';
import useClickOutside from "./useClickOutside";

export const PopoverPicker = ({ color, onChange, name, setIsSortable }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const close = useCallback(() => toggle(false), []);
  const availableColors =  [
    "#000000", // black
    "#3a1c0d", "#7b3e1e", "#b26436", "#e89c63", // brown
    "#C70039", "#ff0000", "#ff5050", "#ff9999", "#ffc4c4", // red
    "#ff7f00", "#ff9933", "#ffb347", "#ffd1a6", // orange
    "#ffff00", "#ffff66", // "#ffff99", "#ffffcc", // yellow
    "#008000", "#00a86b", "#00cc8c", "#80e6b3", // green
    "#00ffff", "#33cccc", "#66cccc", "#99cccc", // turqoise
    "#0000ff", "#3333ff", "#6666ff", "#9999ff", // blue
    "#800080", "#a64ca6", "#bf73b9", "#d89ee0", // magenta
    "#4b0082", "#6a5acd", "#7b68ee", "#a7a5d5", // purple
    // "#ffffff", // white
  ]
  

  useClickOutside(popover, close, setIsSortable);
  // console.log("who am i", name)
  return (
    <div className="picker" style={{zIndex: "9999", display: "flex"}}>
      {isOpen === false && (<div
        className="swatch"
        style={{ 
          backgroundColor: color, 
          width: "28px", 
          height: "28px", 
          border: "solid 1px"}}
        onClick={() => {
          toggle(true)
          setIsSortable(false)}
        }
      />)}

      {isOpen && (
        <div className="popover" ref={popover} style={{transform: "translateX(-143px)"}}>
          <CloseIcon onClick={() => {
              toggle(false)
              setIsSortable(true)}
            } />
          Vælg farve
          <GithubPicker colors={availableColors} width="240px"
          color={color} category={name} onChange={(category, color) => onChange(color, name)} styles={{overflow: "visible"}}/>
        </div>
      )}
    </div>
  );
};
