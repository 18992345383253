import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './accordion.css'


export default function SimpleAccordion() {
    return (
        <div>
            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                aria-controls="panel1a-content"
                >
                    Hvordan laver jeg en plakat?
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <><div>
                    <li>Forbind til din Strava konto. <a href="https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=http://localhost:3000/studio&response_type=code&scope=read,read_all,activity:read_all"><div className="connect-button" style={{marginBottom: "10px"}}>Forbind til STRAVA</div></a></li>
                    <li>Hvis du har mange aktiviteter på Strava, kan det godt tage lidt tid.</li>
                    <li>Nu kan du designe din plakat. De forskellige typer af aktiviteter du har på din Strava konto vises i højre side.</li>
                    <li>Har du flere forskellige aktivitetstyper, kan du flytte op og ned på dem for at ændre på rækkefølgen de bliver vist i.</li>
                    <li>Du kan justere antallet af aktiviteter fra hver type du vil have med. Og du kan ændre farven som ruterne bliver vist i.</li>
                    <li>Vælg i hvilket format, du kunne tænke dig plakaten i; A4 21x30, B2 50x70 eller B1 70x100.</li>
                    <li>Tilføj plakaten til kurven, hvis du er tilfreds med designet, eller lav en ny, hvis du ikke er tilfreds.</li>
                    <li>Vi forsøger at passe de valgte antal ruter, så plakaten bliver fyldt helt ud. Det betyder, at vi nogen gange fjerner enkelte ruter for at få det til at fremstå visuelt bedre.</li>
                    </div>
                </>
                </AccordionDetails>
            </Accordion>

            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                aria-controls="panel1a-content"
                >
                    Hvorfor ændrer ruternes størrelse sig?
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <div>
                    Plakatens layout defineres automatisk, hvilket er årsag til at størrelserne på ruterne kan ændre sig. Hvis du ikke kan lide,
                    hvordan den specifikke designet plakat ser ud, kan du fjerne eller tilføje antal af ruter. Efter ændring af antal ruter, burde størrelsen ændre sig.
                    Det sker ud fra en række kriterier:
                    <ol>
                    <li>Antal aktiviteter</li>
                    <li>Ratio mellem længdegrad og breddegrad</li>
                    <li>Ratio på det valgte format</li>
                    <li>Margin indtil ruterne</li>
                    <li>Margin mellem ruterne</li>
                    </ol>
                </div>
                </AccordionDetails>
            </Accordion>


            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                aria-controls="panel1a-content"
                >
                    Jeg har flere aktiviteter på min Strava-konto, hvorfor vises de ikke?
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <div>
                Når vi henter dine aktiviteter fra Strava kigger vi på distancen af ruterne. Hvis en aktivitet er mindre en 400 meter lang,
                gemmer vi ikke aktiviteten. Generelt kan det siges, at aktiviteter under 400 meter i distance ikke gør sig så godt på plakaten.
                Udover kriteriet på 400 meter i længde, bliver validiteten af "map" data også sikret.
                </div>
                </AccordionDetails>
            </Accordion>


            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                aria-controls="panel1a-content"
                >
                    Hvorfor er alle valgte aktiviteter ikke med på plakaten
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <div>
                For at få et ensartet og rent look, forsøger vi at have alle rækker fyldt ud med aktiviteter. Derfor vil der ofte blive fjernet
                enkelte aktiviteter fra den aktivitetstype der har flest ruter. Dvs. hvis du har 400 løbeture og 213 cykelture og vores
                tegneprogram finder at layoutet kan blive mere ensartet, hvis der fjernes 3 aktiviteter, vil disse blive fjernet fra løbeturene.
                </div>
                </AccordionDetails>
            </Accordion>


            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                aria-controls="panel1a-content"
                >
                    Plakaten ser ikke rigtig ud, hvad er der galt?
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <div>
                    Hvis det ser ud som om, at der mangler ruter på plakaten, kan det hjælpe at ændre farven en smule for den specifikke aktivitetstype.<br></br>
                    Hvis det ser ud som om, at hele aktivitetstyper er udeladt, er det typisk fordi fluebenet ikke er sat for aktiviteten - eller fordi
                    antallet af aktiviteter er sat til 0.<br></br>
                    Hvis du stadig mener, der er noget galt kan du skrive til support@print-lap.dk. Så hjælper vi dig hurtigst muligt.

                </div>
                </AccordionDetails>
            </Accordion>


            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                aria-controls="panel1a-content"
                >
                    Hvornår kan jeg forvente min plakat?
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <div>
                Afsendelse af plakater sker typisk allerede efter en eller to arbejdsdage. Leveringen varierer alt efter, hvorfra i Europa plakaterne bliver afsendt.
                Der er typisk afsendelse fra Tyskland, Spanien eller de Baltiske lande. Normalt tager det mellem 5 - og 12 arbejdsdage.
                De udenlandske logistikfirmaer overleverer ikke altid pakken til PostNord med email og telefonnummer - vi anbefaler derfor,
                at du tilføjer pakken med trackingnummer i din PostNord app, så du kan følge den der.
                </div>
                </AccordionDetails>
            </Accordion>


            <Accordion id="accordionHoved">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    Kan jeg fortryde mit køb?
                </AccordionSummary>
                <AccordionDetails id="accordionDet">
                <div>
                    Plakaten er specialdesignet og et personligt aftryk af lige nøjagtig dine ture på Strava.
                    Vores producent af plakater tager ikke i mod returvarer på baggrund af fortrydelse.
                    Vi kan derfor ikke tilbyde den almindelige fortrydelsesret på 14 dage.
                    Du har naturligvis stadig reklamationsret såfremt plakaten er beskadiget ved ankomst.
                </div>
                </AccordionDetails>
            </Accordion>

        </div>
    );
    }
