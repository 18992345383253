import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import React from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Row, Col } from 'react-bootstrap'

import { useState } from "react";
import { CategorySettings } from "../Poster/SortableItem";
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useCart } from 'react-use-cart';



function VerticalToggleButtons(props) {
  const setFormat = props.setFormat
  const format = props.parsedFormat
  const prices = props.prices
  const [view, setView] = React.useState(format);
  const handleFormatChange = props.handleFormatChange
  const handleChange = (event, nextView) => {
    if (nextView !== null){
      handleFormatChange(event)
      setFormat(nextView);
      setView(nextView)}
  };

  return (
    <>
      <h6 style={{marginTop: "10px", paddingLeft: "10px"}}>Størrelse</h6>
      <ToggleButtonGroup
        orientation="vertical"
        value={format}
        exclusive
        onChange={handleChange}
        style={{
          marginTop: '0px', /* Add some spacing between the heading and buttons */
          marginBottom: '10px',
          // border: '1px solid #ccc', /* Add a border around the buttons */
          borderRadius: '5px', /* Add rounded corners to the border */
          paddingLeft: '10px', /* Add some padding to the buttons */
          fontFamily: "monReg"
        }}
      >
        <ToggleButton
          value="A4"
          aria-label="list"
          style={{
            backgroundColor: view === 'A4' ? '#64a34395' : '#fff', /* Change background color when selected */
            fontFamily: "monSemi",
            color: "#000"
          }}
        >
          A4 (210x300) - {prices["A4"]} kr.
        </ToggleButton>
        <ToggleButton
          value="B2"
          aria-label="module"
          style={{
            backgroundColor: view === 'B2' ? '#64a34395' : '#fff', /* Change background color when selected */
            color: view === 'B2' ? '#000' : '#000', /* Change text color when selected */
            fontFamily: "monSemi"

          }}
        >
          B2 (500x700) - {prices["B2"]} kr.
        </ToggleButton>
        <ToggleButton
          value="B1"
          aria-label="quilt"
          style={{
            backgroundColor: view === 'B1' ? '#64a34395' : '#fff', /* Change background color when selected */
            color: view === 'B1' ? '#000' : '#000', /* Change text color when selected */
            fontFamily: "monSemi"
          }}
        >
          B1 (700x1000) - {prices["B1"]} kr.
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}




const DrawerSettings = React.memo((props) => {
  const { open, setOpen, listData, handleDragEnd, ChangeCount, 
    ChangeColor, ChangeCheck, format, handleFormatChange, settings, currentPosterss, 
    prices, setFormat, isSortable, setIsSortable, saveTheAdded} = props;
  const [john, setJohn] = useState("A4");
  const { addItem } = useCart();

  const btStyles = {
    button: {
      // backgroundColor: 'rgb(43, 85, 45)',
      background: 'linear-gradient(to right, #80b187, #33a02c)',
      fontFamily: "monFat",
      color: 'white',
      textTransform: 'none',
      borderRadius: '0px',
      height: '40px',
      width: '100%',
      '& .MuiButtonLabel': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        background: 'linear-gradient(to right, #f22, #ff2, #2f2, #2ff, #22f, #f2f, #f22)',
      },
      marginTop: "10px",
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
    },
  };

  return (
      <Drawer open={open} anchor={"right"} onClose={() => setOpen(false)}>
          <Row>
            <Col><ExitToAppIcon onClick={() => setOpen(false)}/></Col>
            <Col><ExitToAppIcon style={{float: "right"}} onClick={() => setOpen(false)}/></Col>
          </Row>
          <h5 style={{padding:"10px"}}>Plakatindstillinger</h5>
          <Col key="settings_control" style={{maxWidth: "300px", minWidth: "300px", padding: "10px"}}>
          <Col key="settings_control1" style={{maxWidth: "230px", minWidth: "230px"}}>

            <CategorySettings 
                extraPrefix="drawer"
                categories={listData} 
                handleDragEnd={handleDragEnd} 
                ChangeCount={ChangeCount} 
                ChangeColor={ChangeColor}
                ChangeCheck={ChangeCheck}
                key="categorySettingsDrawer"
                isSortable={isSortable}
                setIsSortable={setIsSortable}
            />

{/* () => saveTheAdded('posterId_' + currentPosterss, prices[format], format, JSON.stringify(listData), JSON.stringify(settings)) */}
          </Col>
          <VerticalToggleButtons parsedFormat={format} handleFormatChange={handleFormatChange} setFormat={setFormat} prices={prices}/>

          <div key={"posterId_" + currentPosterss}>
                    <Button 
                    style={btStyles.button}
                    onClick={() => saveTheAdded('posterId_' + currentPosterss, prices[format], format, JSON.stringify(listData), JSON.stringify(settings))}>
                    {/* // onClick={() => addItem( */}
                    {/* //   {"id": "posterId_" + currentPosterss, "price": prices[format], "name": format,  */}
                    {/* //   "quantity": 1, "listdata": JSON.stringify(listData), "settings": JSON.stringify(settings)})}> */}
                        Tilføj til kurv
                    </Button>
                  </div>

          </Col>

      </Drawer>
  )

})

export default DrawerSettings;
