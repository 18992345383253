import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Cart } from '../ShoppingCart/cart'
import Typography from '@mui/material/Typography';
import { useCart } from 'react-use-cart'
import Grid from '@mui/material/Grid';
import CheckoutForm from './checkoutform.js'
import {ShippingInfo, CartInfo} from './delivery';
import Loader from '../Loader/loader';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import './stepper.css'
import { 
    TextField 
  } from '@mui/material';

const StepperObject = (header, content) => {
    return (
        <div className="shad-no-hover">
            <h5>{header}</h5>
            <br></br>
            {content}
        </div>
    )
}

const steps = ['Indtast oplysninger', 'Betaling', 'Ordrebekræftelse'];

export default function HorizontalLinearStepper() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [gotData, setGotData] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const navMap = ["/checkout", "/checkout/betal", "/checkout/ordre"]
  const navigate = useNavigate()
  const {
    items,
    totalItems,
    cartTotal,
    emptyCart,
  } = useCart()
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [leveringsDetaljer, setLeveringsDetaljer] = React.useState({})
  const [displayContent, setDisplayContent] = React.useState()
  const location = useLocation()

  React.useEffect(() => {
    async function getOrderDetails(csid){
      setIsLoading(true)
      const response = await fetch(`${API_BASE_URL}/get-order/${csid + '__csid__' + JSON.parse(window.localStorage.getItem("athlete_id"))}`)
      const data = await response.json()
      return data
    }

    let path = location.pathname
    if (navMap[activeStep] !== path){
        console.log(path)
        if (path === "/checkout/ordre"){
          setActiveStep(2)
          const query = new URLSearchParams(window.location.search);
          if (query.get("success")) {
            let john = getOrderDetails(query.get("csid")).then(
              (response) => {
                  console.log(response)
                  setDisplayContent(response)
                  setIsLoading(false)
                  if (Object.keys(response["leveringsDetaljer"]).length > 0){
                    emptyCart()
                    setGotData(true)
                }
              }
            )
            console.log(query)
            // empty cart here
          } else {
            console.log("something went wrong")
          }
        } else if (path === "/checkout/betal"){
          setActiveStep(0)  
        } else {
        setActiveStep(navMap.indexOf(path))
      }
    }


  }, [location])

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    navigate(navMap[activeStep+1]);

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };



  const handleBack = () => {
    navigate(navMap[activeStep-1]);
    
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} style={{maxWidth: "500px", flexFlow: "wrap"}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} 
                id="stepperHead"
              // className={classes.stepperhead}
              >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

          {(activeStep === 0 && <>
            <Grid item={true} xs={0} sm={12} marginBottom="60px"></Grid>

            <Grid container justifyContent="center">
                <Grid item={true} xs={11} md={5}>
                    {StepperObject("Indkøbskurv", <Cart  blankTarget={true} />)}
                </Grid>
                <Grid item={true} xs={12} md={.5} marginBottom="60px"></Grid>
                <Grid item={true} xs={11} md={6}>
                    {StepperObject("Leveringsdetaljer", <CheckoutForm  leveringsDetaljer={leveringsDetaljer} setLeveringsDetaljer={setLeveringsDetaljer} handleNext={handleNext}/>)}
                </Grid>
            </Grid>
          </>)}

          {activeStep === 1 && <>
            <Grid item={true} xs={0} sm={12} marginBottom="60px"></Grid>

            <Grid container justifyContent="center">

                    <Grid item={true} xs={11} md={5.5}>
                        {StepperObject("Indkøbskurv", <Cart enableEdit={false} blankTarget={true} />)}
                    </Grid>
                    <Grid item={true} xs={12} md={.5} marginBottom="60px"></Grid>
                    <Grid item={true} xs={11} md={5.5}>
                        <ShippingInfo shippingInfo={leveringsDetaljer}/>
                        <form action={`${API_BASE_URL}/create-checkout-session`} method="POST">
                            {}
                            {/* <button type="submit">
                                Checkout
                            </button> 
                            
                            TODO de må ikke kunne gå tilbage på betal
                            */}
                            <input style={{display: "none"}} type="text" name="totalCart" value={JSON.stringify(cartTotal)}/>
                            <input style={{display: "none"}} type="text" name="kurv" value={JSON.stringify(items)} />
                            <input style={{display: "none"}} type="text" name="kunde" value={JSON.stringify(leveringsDetaljer)} />



                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              id="betalKnap"
                              // className={classes.button}
                              style={{fontFamily: "monFat", marginTop: "20px"}}
                              >
                              Betal
                          </Button>

                        </form>
                    </Grid>
                </Grid>
          </>}

          {activeStep === 2 && isLoading === false && gotData && <>
            <Grid item={true} xs={0} sm={12} marginBottom="60px"></Grid>

            <Grid container justifyContent="center">
              <Grid item={true} xs={11} style={{marginTop:"50px"}}>
                <h2>Tak! Vi er gået i gang med at printe din ordre.</h2>
                <p style={{marginTop:"20px", marginBottom:"40px"}}>Du vil inden længe modtage en ordrebekræftelse på mail. <br></br>
                  Se din ordre og leveringsdetaljer nedenfor. Skriv til info@print-lap.dk, hvis du vil lave ændringer.
                </p>
              </Grid>
              <Grid item={true} xs={11} md={4.5} style={{padding: "20px"}}>
                { <CartInfo orderDetails={displayContent["orderDetails"]}/> }
                {/* {StepperObject("Indkøbskurv", <Cart enableEdit={false} blankTarget={true} />)} */}
              </Grid>
              <Grid item={true} xs={12} md={3} style={{marginBottom:"40px"}}></Grid>
              <Grid item={true} xs={11} md={4.5}>
                <ShippingInfo shippingInfo={displayContent["leveringsDetaljer"]} withoutBox={true}/>
              </Grid>

              {/* {StepperObject("Ordre #" + displayContent["orderDetails"].orderId, <Cart enableEdit={false} blankTarget={true} />)} */}

            </Grid>
          </>
          }

          {
            activeStep === 2 && isLoading === false && gotData === false && <>Ordren findes ikke.</>
          }

          {
          activeStep === 2 && isLoading &&
            <Loader/>
          }

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep === 1 && <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              style={{fontFamily: "monSemi"}}
            >
              Tilbage
            </Button>}
            {/* <Box sx={{ flex: '1 1 auto' }} />

            <Button onClick={handleNext} disabled={totalItems === 0}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> */}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
