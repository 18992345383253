import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
    const [open, setOpen] = React.useState(true);
    const text = props.text
    const servere = props.servere
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    };

    return (
    <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={servere} sx={{ width: '100%' }} style={{fontFamily: "monReg"}}>
                {text}
            </Alert>
        </Snackbar>
    </Stack>
    );
    }
