import Header from '../components/Header/header.js'
import React, { Fragment, useRef, useState } from "react";
import Grid from '@mui/material/Grid';
import stravaLogo from "../assets/strava-logo.png"
import PoolIcon from '@mui/icons-material/Pool';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import fun from "../assets/fun.png"
import achiever from "../assets/achiever.png"
import intermediate from "../assets/intermediate.png"
import coverVideo from "../assets/Cover video.mp4"

const Home = () => {
    const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL
    const [isPlaying, setIsPlaying] = useState(true)
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
      if (isPlaying){
        setIsPlaying(false)
        vidRef.current.pause();
      } else {
        setIsPlaying(true)
        vidRef.current.play();
      }
    }

    return (
    <Fragment>
      <Grid container>
        {/* <Grid item={true} xs={0} sm={1}/> */}
          <Grid item={true} xs={12} sm={12}>
            
          <Header />
          {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri={{ url_for('studio', _external=True)}}&response_type=code&scope=read,read_all,activity:read_all */}
          {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=localhost:3000/studio&response_type=code&scope=read,read_all,activity:read_all */}
            <Grid container justifyContent="center" spacing={0} marginTop="20px" maxWidth="1200px" marginLeft="auto" marginRight="auto">
            <Grid item={true} sm={12} xs={0} marginTop="60px"></Grid>

              <Grid item={true} md={5} sm={5} xs={11}>
                <h1>Skab fede plakater <br/>
                af dine aktiviteter fra
                <img src={stravaLogo} width="185px"  alt="Strava logo"></img></h1>
                
              </Grid>
              <Grid item={true} md={5} sm={5} xs={11}>
              <a href={`https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=${DOMAIN_URL}/studio&response_type=code&scope=read,read_all,activity:read_all`}>
                <div className="shad">
                    <h3>Kom i gang ved at forbinde til din Strava konto!</h3>
                    <div className="connect-button">Forbind til STRAVA</div>
                </div>
                </a>
              </Grid>
              {/* distance when not mobile  */}
              <Grid item={true} sm={12} xs={0} marginTop="80px"></Grid>
              <Grid item={true} sm={3.3} xs={11} textAlign="center"  padding="20px">
                <img src={achiever} width="100%" style={{borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px"}} alt="En 70x100 cm plakat af Strava aktiviteter/ruter, inkluderer svømning, virtualride, løb, alpin skiløb, hike, gåture og cykling"></img>
                B1 - 70x100cm Plakat
              </Grid>
              <Grid item={true} sm={3.3} xs={11} textAlign="center"  padding="20px">
              <img src={intermediate} width="100%"  style={{borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px"}} alt="En B2 størrelse plakat på 50x70 cm af Strava aktiviteter, der inkluderer løbe - og gåture"></img>
                B2 - 50x70cm Plakat
              </Grid>
              <Grid item={true} sm={3.3} xs={11} textAlign="center" padding="20px">
              <img src={fun } width="100%"  style={{borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px"}} alt="En mindre plakat i A4 størrelse der inkluderer løbeture"></img>
                A4 - 21x30cm Plakat
              </Grid>
              <Grid item={true} sm={12} xs={0} marginTop="80px"></Grid>

              <Grid item={true} md={10} sm={10} xs={11} textAlign="center">
                <h2>
                  Design kvalitets plakater fra GPS afmærkningen af dine aktiviteter.
                  Forevig dit hårde arbejde 
                  <PoolIcon/><DirectionsBikeIcon/><DirectionsRunIcon/><SportsScoreIcon/>
                </h2>
              </Grid>

              <Grid item={true} md={12} sm={12} xs={12} textAlign="center" marginTop="80px">
                <video  ref={vidRef} playsInline autoPlay muted loop id="video" onClick={handlePlayVideo} onMouseEnter={(e) => {e.target.style.cursor = "pointer"}}>
                    <source src={coverVideo} type="video/mp4"/>
                </video>
              </Grid>
            </Grid>

          </Grid>
        {/* <Grid item xs={0} sm={1}/> */}
      </Grid>
    </Fragment>
    
  );
};

export default Home;