import React, { useState, useEffect } from 'react';
import BrushIcon from '@mui/icons-material/Brush';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import { useCart } from 'react-use-cart'

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


function SettingsButton(props) {
  const { addItem } = useCart()
  const [isVisible, setIsVisible] = useState(true);
  const settings = props.settings
  const currentPosterss = props.currentPosterss
  const prices = props.prices
  const listData = props.listData
  const format = props.format
  const saveTheAdded = props.saveTheAdded

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth < 900);
    };
    window.addEventListener('resize', handleResize);
    setIsVisible(window.innerWidth < 900)
    // cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const openDrawer = props.setOpenDrawer
  const buttonStyle = {
    display: isVisible ? 'block' : 'none',
    position: 'fixed',
    top: '20%',
    right: '0',
    padding: '8px',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    lineHeight: '1.5em',
    fontSize: '16px',
    // paddingRight: "10px",
    fontWeight: 'bold',
  };

  const shopbut = {...buttonStyle}
  shopbut["top"] = "30%"

  return (<>
        <Button style={buttonStyle} onClick={() => openDrawer(true)}>
            <ArrowBackIosNewIcon style={{fontSize:"small"}}/>
            <BrushIcon/>
        </Button>
        {/* () => saveTheAdded('posterId_' + currentPosterss, prices[format], format, JSON.stringify(listData), JSON.stringify(settings)) */}
        <Button style={shopbut}
        onClick={() => saveTheAdded('posterId_' + currentPosterss, prices[format], format, JSON.stringify(listData), JSON.stringify(settings))}>
            <AddShoppingCartIcon/>
        </Button>
    </>
  );
}

export default SettingsButton;
