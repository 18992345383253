import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import favicon from './favicon.png'
import './footer.css'

export default function Footer(props) {

  const content = {
    'brand': { image: favicon, width: 15     },
    'copy': '© 2022 Print-lap',
    'link1': 'FAQ',
    'link2': 'Om',
    'link3': 'Betingelser & Aftaler',
    'link4': 'Fourth Link',
    ...props.content
  };

  let brand;

  if (content.brand.image) {
    brand = <span style={{fontSize:"0.7em"}}><img src={ content.brand.image } alt="" width={ content.brand.width } /> Print-Lap</span>
  } else {
    brand = content.brand.text || '';
  }

  return (
    <footer>
      <Container>
        <Box py={6} display="flex" flexWrap="wrap" alignItems="center" id="footerRoot">
          <Link href="/" color="inherit" underline="none" id="footerMLink">
            {brand}
          </Link>
          <Box component="nav" id="footerNav">
            <Link href="/faq" variant="body1" color="textSecondary" id="footerLink">{content['link1']}</Link>
            <Link href="#" variant="body1" color="textSecondary" id="footerLink">{content['link2']}</Link>
            <Link target="_blank" href="/terms.pdf" variant="body1" color="textSecondary" id="footerLink">{content['link3']}</Link>
          </Box>
          <Typography color="textSecondary" style={{fontFamily: "monReg"}} component="p" variant="caption" gutterBottom={false}>{content['copy']}</Typography>
        </Box>
      </Container>
    </footer>
  );
}