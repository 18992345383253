import { useCart } from 'react-use-cart'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import thumbnail from './thumbnail.png'
import Button from '@mui/material/Button';




export function Cart(props) {
    const {
      isEmpty,
      cartTotal,
      totalUniqueItems,
      items,
      updateItemQuantity,
      removeItem,
      emptyCart,
      metadata,
      totalItems
    } = useCart();
    if (isEmpty) return <p>Ingeting i kurven</p>;
    function handleClick(posterid) {
        props.navigate("/studio?" + posterid);
        props.setOpen(false)
      }

    let enableEdit = props.enableEdit 
    if (enableEdit === undefined){
      enableEdit = true
    }
    let blankTarget = props.blankTarget
    if (blankTarget === undefined){
      blankTarget = false
    }

    return (
      <div>
          {/* ({totalUniqueItems} - {cartTotal} - {totalItems}) */}
        
    
        {/* {!isEmpty && <button onClick={emptyCart}>Tøm kurv</button>} */}
        <Grid container>

            { <>
            <Grid item={true} xs={2} textAlign="center">Plakat</Grid>
            <Grid item={true} xs={3} textAlign="center">Enhedspris</Grid>
            <Grid item={true} xs={1}></Grid>
            <Grid item={true} xs={1} textAlign="center">Antal</Grid>
            <Grid item={true} xs={1}></Grid>
            <Grid item={true} xs={2} textAlign="center">Total</Grid>
            {/* <Grid item={true} xs={2} textAlign="center" onClick={() => emptyCart()}>Tøm</Grid> */}
            <Grid item={true} xs={12} marginBottom="10px" marginTop="10px"><Divider/></Grid>
            </>}
          {items.map((item) => (
            <Grid container key={item.id} textAlign="center" alignItems="center">
                        {blankTarget === false && <Grid item={true} xs={2}
                            onMouseOver={(e) => {e.target.style.cursor = 'pointer'}}
                            onClick={() => handleClick(item.id)} 
                            >  
                        {/* object-fit: cover;
                          width: 100%; */}          
                            <div
                                style={{position: "relative", textAlign: "center"}}>
                                <img width="50%" src={thumbnail}/>
                            <Typography 
                                style={{
                                    fontSize: "clamp(0.9375rem, -0.1042rem + 4.4444vw, 1.5625rem)", 
                                    position: "absolute", 
                                    top: "20%", left: "33%", color: "gray"}}
                            >{item.name}</Typography>
                            </div>
                            
                        </Grid>}
                        {blankTarget && 
                          
                          <Grid item={true} xs={2}
                            // onMouseOver={(e) => {e.target.style.cursor = 'pointer'}}
                            >  <Link to={"/studio?" + item.id} target="_blank">
                        {/* object-fit: cover;
                          width: 100%; */}          
                            <div
                                style={{position: "relative", textAlign: "center"}}>
                                <img width="30%" src={thumbnail}/>
                            <Typography 
                                style={{
                                    fontSize: "clamp(0.9375rem, -0.1042rem + 4.4444vw, 1.5625rem)", 
                                    position: "absolute", 
                                    top: "20%", left: "33%", color: "gray"}}
                            >{item.name}</Typography>
                            </div>
                        </Link>
                            
                        </Grid>
                        }


                        <Grid item={true} xs={3}>
                            {item.price}
                        </Grid>
                        {enableEdit && <Grid item={true} xs={1}
                            onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                            color="grey"
                            onMouseOver={(e) => {e.target.style.color = '#683131'; e.target.style.cursor = 'pointer'}}
                            onMouseOut={(e) => e.target.style.color = 'grey'}
                        >
                            <RemoveCircleIcon/>
                        </Grid>}
                        {
                          enableEdit === false && <Grid item={true} xs={1}/>
                        }
                        <Grid item={true} xs={1}> {item.quantity} </Grid>
                        {enableEdit &&
                          <Grid item={true} xs={1}
                            onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                            color="grey"
                            onMouseOver={(e) => {e.target.style.color = 'green' ; e.target.style.cursor = 'pointer'}}
                            onMouseOut={(e) => e.target.style.color = 'grey'}
                        >
                            <AddCircleIcon/>
                        </Grid>}
                        {
                          enableEdit === false && <Grid item={true} xs={1}/>
                        }
                        <Grid item={true} xs={2}> {item.itemTotal} DKK </Grid>
                        {enableEdit && <Grid item={true} xs={2} color="grey"
                            onClick={() => removeItem(item.id)}
                            onMouseOver={(e) => {e.target.style.color = '#683131'; e.target.style.cursor = 'pointer'}}
                            onMouseOut={(e) => e.target.style.color = 'grey'}
                        >
                            <DeleteForeverIcon/>
                        </Grid>}
                        {
                          enableEdit === false && <Grid item={true} xs={2}/>
                        }
            {/* <li key={item.id}>
              {item.quantity} x {item.name}
              <button
                onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
              >
                -
              </button>
              <button   
                onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
              >
                +
              </button>
              <button onClick={() => removeItem(item.id)}>Remove &times;</button>
            </li> */}
                <Grid item={true} xs={12} marginBottom="20px" marginTop="20px">
                    <Divider/>
                </Grid>
            </Grid>))}
            {blankTarget && 
              <>
                <Grid item={true} xs={2} textAlign="center">Levering</Grid>
                <Grid item={true} xs={6}></Grid>
                <Grid item={true} xs={2} textAlign="center">50 DKK</Grid>
              </>
            }
            <Grid item={true} xs={6.2}></Grid>

            <Grid item={true} xs={2}>Total</Grid>

            <Grid item={true} xs={2}>{blankTarget ? cartTotal + 50 : cartTotal} DKK</Grid>
            <Grid item={true} xs={1}></Grid>
          </Grid>
      </div>
    );
  }

// export function CartIteme()