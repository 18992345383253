import Header from '../components/Header/header.js'
import React, { Fragment } from "react";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import HorizontalLinearStepper from '../components/Stepper/stepper.js'


const Checkout = () => {
    return (
    <Fragment>
      <Grid container>
          <Grid item={true} xs={12} sm={12}>
            
          <Header />
          {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri={{ url_for('studio', _external=True)}}&response_type=code&scope=read,read_all,activity:read_all */}
          {/* https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=localhost:3000/studio&response_type=code&scope=read,read_all,activity:read_all */}
            <Grid container justifyContent="center" spacing={0} marginTop="20px" maxWidth="1200px" marginLeft="auto" marginRight="auto">
            <Grid item={true} sm={12} xs={0} marginTop="60px"></Grid>
            
            <HorizontalLinearStepper/>
            
            

            </Grid>
          </Grid>
      </Grid>
    </Fragment>
    
  );
};

export default Checkout;


// <DawaAutocomplete>
// {({ value, suggestions, handleChange }) => {
//   console.log(suggestions)
//   return (
//     <div>
//       <input type='text' value={ value } onChange={ handleChange } />
//       { suggestions.map(suggestion => (
//         <li key={ suggestion.adresse.id }>{ suggestion.tekst }</li>
//       )) }
//     </div>
//   )
// }}
// </DawaAutocomplete>