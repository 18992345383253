import Grid from '@mui/material/Grid';


const Loader = () => {
    return (
        <Grid container justifyContent="center">
        <Grid item={true} xs={12} sm={12} marginBottom="60px"></Grid>
        <Grid item={true} xs={6} sm={4} md={2}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100%">
            <circle cx="50" cy="50" r="40" stroke="#d3a6e2" strokeWidth="10" fill="none">
              <animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite"
                from="0,251.2" to="251.2,0" begin="0s"/>
              <animate attributeName="stroke-dashoffset" dur="3s" repeatCount="indefinite"
                values="0;251.2" begin="0s"/>
            </circle>
            <circle cx="50" cy="50" r="30" stroke="#abe4e2" strokeWidth="10" fill="none">
              <animate attributeName="stroke-dasharray" dur="3s" repeatCount="indefinite"
                from="0,188.5" to="188.5,0" begin="0s"/>
              <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite"
                values="0;188.5" begin="0s"/>
            </circle>
            <circle cx="50" cy="50" r="20" stroke="#fbffea" strokeWidth="10" fill="none">
              <animate attributeName="stroke-dasharray" dur="2.4s" repeatCount="indefinite"
                from="0,125.6" to="125.6,0" begin="0s"/>
              <animate attributeName="stroke-dashoffset" dur="2.7s" repeatCount="indefinite"
                values="0;125.6" begin="0s"/>
            </circle>
          </svg>
        </Grid>
        <Grid item={true} xs={12} sm={12} marginBottom="60px"></Grid>
      </Grid>
    )
}

export default Loader
