import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

const ShippingInfo = ({ shippingInfo, withoutBox }) => {
  const { mail, address, fornavn, efternavn, mobile } = shippingInfo;
  const labelStyle = { fontFamily: "monReg" };
//   const paperStyle = { padding: "16px", background: "#2196f380", color: "#fff" };
  const paperStyle = {
    fontFamily: "monReg",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: withoutBox ? "rgba(255,255,255,0.0)" : "rgba(255,255,255,0.4)",
    boxShadow: withoutBox ? "unset" : "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    // border: "unset",
}
  const listItemStyle = { display: "flex", justifyContent: "space-between" };
  const valueStyle = { fontFamily: "monReg" };

  return (
    <Paper style={paperStyle}>
      <Typography variant="h5" style={labelStyle}>
        Leveringsinformation
      </Typography>
      <List>
        <ListItem style={listItemStyle}>
          <Typography style={labelStyle}>Fornavn</Typography>
          <Typography style={valueStyle}>{fornavn}</Typography>
        </ListItem>
        <ListItem style={listItemStyle}>
          <Typography style={labelStyle}>Efternavn</Typography>
          <Typography style={valueStyle}>{efternavn}</Typography>
        </ListItem>
        <ListItem style={listItemStyle}>
          <Typography style={labelStyle}>Adresse</Typography>
          <Typography style={valueStyle}>{address}</Typography>
        </ListItem>
        <ListItem style={listItemStyle}>
          <Typography style={labelStyle}>Mobil</Typography>
          <Typography style={valueStyle}>{mobile}</Typography>
        </ListItem>
        <ListItem style={listItemStyle}>
          <Typography style={labelStyle}>Email</Typography>
          <Typography style={valueStyle}>{mail}</Typography>
        </ListItem>
      </List>
    </Paper>
  );
};



const CartInfo = ({ orderDetails }) => {
  return (<>
    <Grid container>
        <Grid item={true} xs={12}>        <h5>Ordre #{orderDetails["orderId"]}</h5>
</Grid>
  
        <Grid item={true} xs={2}>Plakat</Grid>
        <Grid item={true} xs={3}>Enhedspris</Grid>
        <Grid item={true} xs={1}></Grid>
        <Grid item={true} xs={1}>Antal</Grid>
        <Grid item={true} xs={1}></Grid>
        <Grid item={true} xs={2}>Total</Grid>
        {
          orderDetails.products.map((item) => (
              <Grid container alignItems="center" key={item.id}>
                <Grid item={true} xs={2}>
                  {item.type}
                </Grid>
                <Grid item={true} xs={3}>
                  {item.price}
                </Grid>
                <Grid item={true} xs={1}></Grid>
                <Grid item={true} xs={1}>
                  {item.quantity}
                </Grid>
                <Grid item={true} xs={1}></Grid>
                <Grid item={true} xs={2}>
                  {item.quantity * item.price}
                </Grid>
              </Grid>
            )
          )
        }
    </Grid>
  </>)
}

export {ShippingInfo, CartInfo};