import React, { useEffect, useState, useRef } from 'react';
import { Canvg } from 'canvg';
import { useScreenshot } from 'use-react-screenshot'
import DataTable from '../DataTable/datatable';
import CustomizedSnackbars from '../Alert/alert';
import Button from '@mui/material/Button';
import './poster.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCart } from 'react-use-cart';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import {
  arrayMove,
} from '@dnd-kit/sortable';
import { CategorySettings } from './SortableItem';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs'
import html2canvas from 'html2canvas'
import svgCrowbar from 'svg-crowbar';
import Loader from '../Loader/loader';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import DrawerSettings from '../Drawer/drawer.js'
import Box from '@material-ui/core/Box'
import SettingsButton from './SettingsButton';
import { func } from 'prop-types';


class MyRegExp extends RegExp {
  [Symbol.matchAll](str) {
    const result = RegExp.prototype[Symbol.matchAll].call(this, str);
    if (!result) {
      return null;
    }
    return Array.from(result);
  }
}


function indexOfMax(arr) {
  if (arr.length === 0) {
      return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
          maxIndex = i;
          max = arr[i];
      }
  }

  return maxIndex;
}

function Poster(props){
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const showConfig = props.showConfig
    const setNum = props.setNum
    const actualData = props.actualData
    const loadFromStore = props.loadFromStore
    const changeLogin = props.changeLogin
    const setProvideMessage = props.setProvideMessage
    const location = useLocation()
    const { addItem, getItem } = useCart();
    const [currentIdList, setCurrentIdList] = useState([])
    const [notifyText, setNotifyText] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isSortable, setIsSortable] = useState(true)
    const [err, setErr] = useState('');
    const [john, setJohn] = useState(0)
    const [currentPosterId, setPosterId] = useState(0)
    // const [svgs, setSvg] = useState([])
    const [doSomething, setDoSomething] = useState(false)
    const [svgData, setSvgData] = useState([])
    // const [number_of_activities, setNumber] = useState(0)
    const [settings, setSettings] = useState({'x':0, 'y':0, 'multiline': {'use': false}, 'format': 'B2'})
    const [listData, setListData] = useState([
      {'name': 'lars', 'position': 4, 'id': 4, 'count': 0, 'color': '#FFFFFF', 'checked': false}, 
      {'name': 'preben', 'position': 1, 'id': 1, 'count': 0, 'color': '#FFFFFF', 'checked': true}, 
      {'name': 'ole', 'position': 2, 'id': 2, 'count': 0, 'color': '#FFFFFF', 'checked': true}, 
      {'name': 'john', 'position': 3, 'id': 3, 'count': 0, 'color': '#FFFFFF', 'checked': true}])
    // const [usageData, setUsageData] = useState(0)
    const numberPattern = new RegExp('[0-9]*')
    const svgIdPattern = new MyRegExp('svg\\sid\\=\\"(\\d+)', 'g');
    const prices = {'A4': 200, 'B2': 250, 'B1': 300}
    const [format, setFormat] = useState('B2')
    const [definingState, setDefiningState] = useState()
    const [image, takeScreenshot] = useScreenshot()
    const [svgsStoredInLocals, setsvgsStoredInLocals] = useState(false)
    const [isMounted, setIsMounted] = useState(false);
    const updateCategories = (resp) => {
      let categoryData = []
      var index = 1
      for (let i in resp) {
        categoryData.push({
          'name': i,
          'id': index,
          'position': index,
          'count': resp[i]['count'],
          'current': resp[i]['count'],
          'color': resp[i]['color'],
          'checked': resp[i]['checked']
        })
        index += 1
      }

      setListData(categoryData)
      setDefiningState(categoryData)
    }

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    // const translateX = (window.innerWidth - 500 * scale) / 2;
    // const translateY = (window.innerHeight - poster.offsetHeight * scale) / 2;
    useEffect(() => {
      function setPosterScale() {
        const poster = document.querySelector('.poster');
        const john = document.querySelector('.gotTheGrid')
        const windowWidth = window.innerWidth;

        let pixel = 600
        if (windowWidth > pixel){
          // poster.style.width = '500px'
          poster.style.transform = `scale(1)`
          poster.style.marginLeft = 'unset'
          poster.style.marginRight = '50px'
          john.style.width = `500px`

        } else {
          // poster.style.width = 'unset'
          const scale = windowWidth * .9 / 500;
          poster.style.marginLeft = 'auto'
          poster.style.marginRight = 'auto'
          john.style.justifyContent = 'center'
          john.style.width = `${scale * 500}px`
          poster.style.transform = `scale(${scale})`;
          
        }
      }
      
      setPosterScale();
      window.addEventListener('resize', setPosterScale);
      return () => window.removeEventListener('resize', setPosterScale);
    }, []);


    useEffect(() => {

      // runs on location, i.e. route, change
      let lookingFor = location.search
      if (lookingFor){
        if (isMounted){
          if (lookingFor.includes('poster')){
            let lookingForId = lookingFor.split('?')[1]

            let currentItem = JSON.parse(window.localStorage.getItem('react-use-cart')).items.find(x => x.id === lookingForId)
            for (let john in JSON.parse(currentItem.listdata)){
              let cit = JSON.parse(currentItem.listdata)[john]
            }
            setListData(JSON.parse(currentItem.listdata))
            setSettings(JSON.parse(currentItem.settings))
            setFormat(currentItem.name)
          }
        }
        else {
          getfdata()
        }
      }
    }, [location, isMounted])

    useEffect(() => {

      let savedSvgs = JSON.parse(window.localStorage.getItem('savedSvgs'))
      if (typeof savedSvgs === undefined || savedSvgs === null || isMounted === true){
      } else if (svgsStoredInLocals){
      }
      else //(typeof db === 'undefined')
      {
        const request = indexedDB.open('poster');
        request.onsuccess = function() {
          const db = request.result;
          var store;
          try {
            const tx = db.transaction('svgs', 'readwrite');
            store = tx.objectStore('svgs');
          }
            catch(e) {
              // store = db.createObjectStore('svgs');
              return
          }
          // const tx = db.transaction('svgs', 'readwrite');
          // const store = tx.objectStore('svgs');
          var allRecords = store.getAll()
          allRecords.onsuccess = function() {
            setIsLoading(true)
            let storedCategories = JSON.parse(window.localStorage.getItem('listData'))
            let storedSettings = JSON.parse(window.localStorage.getItem('settings'))
            
            setListData(storedCategories)
            setSettings(storedSettings)
            
            let storedSvgs = {}
            let theCategories = []
            for (let index = 0; index < allRecords.result.length; index++){
              let svg = allRecords.result[index]
              if (!theCategories.includes(svg.category)){
                storedSvgs[svg.category] = []
                theCategories.push(svg.category)
              }
              storedSvgs[svg.category].push(svg)
            }
            for (let cat in theCategories){
              if (storedSvgs[theCategories[cat]]){
               storedSvgs[theCategories[cat]].sort((a, b) => a.indicator - b.indicator)
            }
            }
            setSvgData(storedSvgs)
            setIsMounted(true)
          }
          allRecords.onerror = () => {
          }
          setIsLoading(false)

        }
        request.onerror = () => {
          getfdata()
        }
      };

    }, [listData, isMounted])
    
    const getfdata = () => {
      {
        const request = indexedDB.open('poster');
        request.onsuccess = function() {
          const db = request.result;
          var store;
          try {
            const tx = db.transaction('svgs', 'readwrite');
            store = tx.objectStore('svgs');
          }
            catch(e) {
              // store = db.createObjectStore('svgs');
              return
          }
          // const tx = db.transaction('svgs', 'readwrite');
          // const store = tx.objectStore('svgs');
          var allRecords = store.getAll()
          allRecords.onsuccess = function() {
            setIsLoading(true)
            
            if (location.search.includes('poster')){

            } else {
            let storedCategories = JSON.parse(window.localStorage.getItem('listData'))
            let storedSettings = JSON.parse(window.localStorage.getItem('settings'))
            
            setListData(storedCategories)
            setSettings(storedSettings)}
            
            let storedSvgs = {}
            let theCategories = []
            for (let index = 0; index < allRecords.result.length; index++){
              let svg = allRecords.result[index]
              if (!theCategories.includes(svg.category)){
                storedSvgs[svg.category] = []
                theCategories.push(svg.category)
              }
              storedSvgs[svg.category].push(svg)
            }
            setIsLoading(false)

            return storedSvgs
          }

        }
        
        request.onerror = function() {
          // we should have data at this point, indicate to the user that it works better in non-private browsing
          // check om storedsvgs, if not we cannot do anything
          const storedSvgs = JSON.parse(window.localStorage.getItem('storedSvgs'))
          if (storedSvgs === null || storedSvgs === undefined || storedSvgs.length === 0){
            window.localStorage.removeItem('savedSvgs')
            window.localStorage.removeItem('loggedIn')
            window.localStorage.removeItem('settings')
            window.localStorage.removeItem('athlete_id')
            window.localStorage.removeItem('listData')
            
            changeLogin(false)
            setProvideMessage('Vi hentede data, men kunne ikke bruge det i klienten. Forsøg i non-private browsing.')
          } else {
            if (!location.search.includes('poster')){

              let storedCategories = JSON.parse(window.localStorage.getItem('listData'))
              let storedSettings = JSON.parse(window.localStorage.getItem('settings'))
              
              setListData(storedCategories)
              setSettings(storedSettings)
            }
            
            const theStorage = JSON.parse(window.localStorage.getItem('storedSvgs'))
            let stateSvgs = {}
            let theCategories = []
            for (let index = 0; index < theStorage.length; index++){
              let svg = theStorage[index]
              if (!theCategories.includes(svg.category)){
                stateSvgs[svg.category] = []
                theCategories.push(svg.category)
              }
              stateSvgs[svg.category].push(svg)
            }
            for (let cat in theCategories){
              if (stateSvgs[theCategories[cat]]){
                stateSvgs[theCategories[cat]].sort((a, b) => a.indicator - b.indicator)
              }
            }
            setSvgData(stateSvgs)
            setIsMounted(true)
            setNotifyText('Det anbefales at skifte til non-private browsing. Funktionaliteten er begrænset i incognito/privat.')
          }
          }
        }
      }

    const triggerSettingUpdate = (count, legends, listlist) => {
      const fetchData = async (inputDict) => {
        const response = await fetch(`${API_BASE_URL}/get_poster_settings`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(inputDict)
        })
        const result = await response.json();

        if (listlist){
          let nlist = []
          let maxindex = indexOfMax(listlist.map(activ => activ.count))
          for (let i = 0; i < listlist.length; i++){
            const currentCat = listlist[i]
            if (i === maxindex){
              currentCat['remove'] = parseInt(result['remove'])
            } else {
              currentCat['remove']= 0
            }
            nlist.push(currentCat)
          }
          // console.log('setting nlist', nlist)
          setListData(nlist)
          window.localStorage.setItem('listData', JSON.stringify(nlist))
        }
        setSettings(result)
        setNum(count)
      }
      window.localStorage.setItem('savedSvgs', count)
      fetchData({'number_of_activities': count, 'legends': legends, 'format': format})
    }

    useEffect(() => {
      setIsLoading(true)
      if (typeof definingState === 'undefined'){
      }
      else if (definingState.length >= 1){
        const fetchData = async (inputDict) => {
          const response = await fetch(`${API_BASE_URL}/get_poster_settings`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(inputDict)
          })
          const result = await response.json();
          setSettings(result)
        }

        let cCount = 0
        for (let i = 0; i < definingState.length; i++){
          cCount += definingState[i].current
        }
        fetchData({'number_of_activities': cCount, 'legends': definingState.length, 'format': format})

        setIsLoading(false)
      }


      setIsLoading(false)
    }, [definingState, svgData])

    
    const getLegendRow = (categories) => {
      let legends = []
      for (let categoryId = 0; categoryId < categories.length; categoryId++){
        
        const currentLegend = svgData[categories[categoryId]][0]
        // Object.values(Object.values(svgData)[categoryId])[0]
        let legendKey = 'legend' + categoryId
        const legend = (
          <Col key={legendKey} style={{paddingLeft: 0, paddingRight: 0, display: 'flex', marginLeft: '5px', marginRight:'5px'}}>
            
            <svg style={{marginLeft:0, marginRight:0, alignSelf: 'center'}} width={settings['legendxy']} height={settings['legendxy']} viewBox='0 -53.333333333333336 53.333333333333336 53.333333333333336'>
              <path
                fill='none'
                d={currentLegend.d}
                stroke={currentLegend.color}
                strokeWidth='2px'
              />
            </svg> 
            <div style={{fontSize: '0.8em', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <span className='legendText'>{categories[categoryId]}</span>
            </div>
          </Col>
        );
        legends.push(legend)
      }
      if (settings['multiline']['use']){
        return (
          <><Row key='legends_1' className='flex-grow-1' style={
            {marginLeft: 'auto', marginRight: 'auto', alignContent: 'center'}
          }>{legends.slice(0, settings['multiline']['number_on_top'])}</Row>
          <Row key='legends_2' className='flex-grow-1' style={
            {marginLeft: 'auto', marginRight: 'auto', alignContent: 'center'}
          }>{legends.slice(settings['multiline']['number_on_top'], legends.length)}</Row></>
        )
      } else {
        return (<Row key='legends' className='flex-grow-1' style={
          {marginLeft: 'auto', marginRight: 'auto', alignContent: 'center'}
        }>{legends}</Row>
        )
      }
    }
    const unde = (element) => element === undefined
    const renderPoster = () => {
      if (listData === undefined || listData.length === 0){
        return null
      }
      if (['lars', 'preben', 'ole', 'john'].includes(listData[0].name)){
        return null;
      }
      if (doSomething === false & Object.keys(svgData).length >= 1){
        setDoSomething(true)
      }
      // sort the svgs in advance so it can't be messed up
      let sortedSvgs = []
      let colorDict = {}
      let usedCategories = []
      
      let test = listData.map(john => svgData[john['name']])
      if (test.some(unde)){
        return null
      }
      
      for (let categoryObject in listData){
        let currentCategory = listData[categoryObject]
        if (currentCategory['current'] === '0' || currentCategory['checked'] === false){
          continue
        }
        usedCategories.push(currentCategory['name'])
        let theSlice = currentCategory['current']
        if (currentCategory.remove >= 0){
          theSlice = parseInt(theSlice) - parseInt(currentCategory.remove)
        }

        let tempData = svgData[currentCategory['name']].slice(0, theSlice)

        sortedSvgs = sortedSvgs.concat(tempData)
        colorDict[currentCategory['name']] = currentCategory['color']
      }
      if (sortedSvgs.length === 0) {
        return null
      }

      const tempCurrentIdList = [];
      const rows = [];
      for (let rowCount = 0; rowCount < settings['y']; rowCount++) {
        const cols = [];
        for (let colCount = 0; colCount < settings['x']; colCount++) {
          const index = rowCount * settings['x'] + colCount;
          if (index >= sortedSvgs.length) {
            break;
          }
    
          const posterSvg = sortedSvgs[index];
          posterSvg.color = colorDict[posterSvg.category]
          const col = (
            <Col key={'svg_col_' + posterSvg.indicator} style={{paddingLeft: 0, paddingRight: 0, display: 'flex'}}>
              
              <svg id={posterSvg.indicator} key={'svg_attr_' + posterSvg.indicator} width={settings['ratiox']} height={settings['ratioy']} viewBox='0 -53.333333333333336 53.333333333333336 53.333333333333336'>
      
                <path
                  key={'svg_path_' + posterSvg.indicator}
                  fill='none'
                  d={posterSvg.d}
                  stroke={posterSvg.color}
                  strokeWidth='2px' //{posterSvg.strokeWidth}
                />
              </svg>
            </Col>
          );
          tempCurrentIdList.push(posterSvg.indicator);
          cols.push(col);
        }
        const row = <Row key={'svg_row_' + rowCount} className='flex-grow-1' style={{paddingLeft: settings['marginx'], paddingRight: settings['marginx'], justifyContent: 'center', marginRight:'0px', marginLeft:'0px'}}>{cols}</Row>;
        rows.push(row);
      }

      if (usedCategories.length >= 2){
        let legendRow = getLegendRow(usedCategories)
        rows.push(legendRow)
      }
      window.localStorage.setItem('settings', JSON.stringify(settings))
      window.localStorage.setItem('listData', JSON.stringify(listData))
      // setCurrentIdList([...tempCurrentIdList])

      return (
        <Container key='posterContainer' className='d-flex flex-column' style={
          {height: '100%', padding: '0px', paddingTop: settings['marginy'], paddingBottom: settings['marginy']}
          }>
          {rows}
        </Container>
      );
    };

    const getCurrentPosterId = () => {
      try {
        let currentCart = JSON.parse(window.localStorage.getItem('react-use-cart'))
        let currentId = currentCart.totalUniqueItems
        // currentCart.items.length + 1
        let lastItem = currentCart.items[currentCart.items.length - 1].listdata
        let lastSettings = currentCart.items[currentCart.items.length - 1].settings
        let split = currentCart.items[currentCart.items.length - 1].id.split('_')[1]
        if (lastItem === JSON.stringify(listData) & (lastSettings === JSON.stringify(settings))) {
          return split
        } else {
          return split + 1
        }
      } catch {
        return 0
      }
    }

    const ChangeCount = async (number) => {
      setIsLoading(true)
      let cCount = 0
      let categoryData = []
      let maxCount = 0
      let maxCategory = null
      let legendCount = 0
      for (let i = 0; i < listData.length; i++){
        if (parseInt(number.target.value) === 0){
        } else {
        let controlValue = String(number.target.value).match(numberPattern)[0]
        if (!parseInt(controlValue) > 0 || parseInt(controlValue) > number.target.max){
          setIsLoading(false)
          return
        }}
        const currentCategory = listData[i]
        if (currentCategory.name === number.target.name){
          currentCategory.current = number.target.value
          currentCategory.checked = (number.target.value > 0) ? true : false
        }
        if (parseInt(currentCategory.current) > maxCount){
          maxCount = parseInt(currentCategory.current)
          maxCategory = currentCategory.name
        }
        cCount += parseInt(currentCategory.current)
        categoryData.push(currentCategory)
        legendCount += (parseInt(currentCategory.current) > 0) ? 1 : 0
      }
      if (settings['no'] !== cCount || ['formatChange', 'initialRender'].includes(number.target.name)){
        
        const response = await fetch(`${API_BASE_URL}/get_poster_settings`, {
          method: 'POST',
          headers: {
                  'Content-Type': 'application/json',
                  },
          body: JSON.stringify(
              {
                  'number_of_activities': cCount,
                  'legends': legendCount,
                  'format': (number.target.name !== 'formatChange') ? settings.format : number.target.format
              }),
          });
        const result = await response.json();
        let newCategoryData = []
        for (let i = 0; i < categoryData.length; i++){
          const currentCategory = categoryData[i]
          if (currentCategory.name === maxCategory){
            currentCategory.remove = parseInt(result['remove'])
          } else {
            currentCategory.remove = 0
          }
          newCategoryData.push(currentCategory)
        }
        setSettings(result)
        setListData(newCategoryData)

      }

      setIsLoading(false)
    }
    const ChangeCheck = (checked, name) => {
      let categoryData = []
      for (let i = 0; i < listData.length; i++){
        const currentCategory = listData[i]
        if (currentCategory.name === name){
          currentCategory.checked = checked
          let countObject = {'target': {'name': name, 'value': checked ? currentCategory.count : 0 }}
          ChangeCount(countObject)
        }
        categoryData.push(currentCategory)
      }
      setListData(categoryData)
    }

    const ChangeColor = (color, name) => {
      let categoryData = []
      for (let i = 0; i < listData.length; i++){
        const currentCategory = listData[i]
        if (currentCategory.name === name)(
          currentCategory.color = color.target.title
        )
        categoryData.push(currentCategory)
      }
      setListData(categoryData)

    }
    
    const handleDragEnd = (event) => {
      const {active, over} = event;
      if(active.id !== over.id) {
        setListData((items) => {
          const oldIndex = items.findIndex(item => item.id === active.id);
          const newIndex = items.findIndex(item => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
          // items: [2, 3, 1]   0  -> 2
          // [1, 2, 3] oldIndex: 0 newIndex: 2  -> [2, 3, 1] 
        });
        
      }
    }
    const handleFormatChange = (event) => {
      setFormat(event.target.value)
      ChangeCount({'target': {'name': 'formatChange', 'value': 0, 'format': event.target.value}})

    }
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = () => {
      let john = document.getElementById('poster')
      // john = john.replace
      htmlToImage.toSvg(john, {
          quality: .1,
          pixelRatio: .1,
          // height: captureRef.current.scrollHeight,
          canvasWidth: 40,
          canvasHeight: 56,
        })
        .then(function (dataUrl) {
          // let john = document.getElementById('poster')
          // const fetchData = async (inputDict) => {
          //   const response = await fetch('/maximise_print', {
          //     method: 'POST',
          //     headers: {
          //       'Content-Type': 'application/json',
          //     },
          //     body: inputDict
          //   })
          // }
          // fetchData({'john': john})
          dataUrl = dataUrl.replace('width="500"', 'width="1000"')

          download(dataUrl, 'my-node.svg');
      });
      // domtoimage.toSvg(document.getElementById('poster'))
      //   .then(function (blob) {
      //     download(blob, 'my-node.png');
      //   });
    
    //   const doc = new jsPDF({
    //     format: 'a4',
    //     unit: 'px',
    //   });
    //   // Adding the fonts.
    //   doc.setFont('Inter-Regular', 'normal');
  
    //   doc.html(reportTemplateRef.current, {
    //     async callback(doc) {
    //       await doc.save('document');
    //     },
      // });
    };
    const anotherTest = () => {
            // Get the DOM element container you want to take a screenshot of
      const container = document.getElementById('jooohn');
      // const container = (<svg width='7.999949999999999px' height='7.999949999999999px' viewBox='0 -53.333333333333336 53.333333333333336 53.333333333333336'><path fill='none' d='M42.97506637917693,-2.073991464402107 L44.56805975115884,-1.6666666666666667 L46.630807123938055,-3.6164831364863823 L45.723831088783754,-5.900004343708704 L42.730984728126735,-10.59256421148488 L35.07780337249736,-18.18656903974049 L32.06331721421038,-22.6797326779798 L31.18767929157355,-26.782742674895577 L32.56298554247465,-33.43410129599314 L31.989026197174457,-41.19455926434179 L30.517341022335398,-46.16021373114255 L28.156145562595487,-50.01057547062846 L24.780064002859927,-51.666666666666664 L23.459896991068952,-49.61380145183313 L21.860635956181405,-41.94401424684536 L15.454176968576965,-33.798564587037795 L14.529047120748615,-30.084353281348378 L8.369192876061947,-17.159679369415556 L33.3878246673198,-7.4379188309330875 L42.98542809935183,-1.9539791808709' stroke='#e31a1c'></path></svg>)
      // Create a canvas element with the same dimensions as the container
      const canvas = document.createElement('canvas');
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;

      // Get the canvas context and set the image smoothing to false
      const ctx = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = false;
      // Render the container onto the canvas using canvg library
      new Canvg(canvas, container.innerHTML);
      // select the container element containing the SVG content

      // extract the SVG content from the container and render it to a canvas element
      // const canvas = svgCrowbar.svgToCanvas(container);
      // new Canvg(ctx, svgContent);
      // Convert the canvas to a data URL with low quality/pixel screenshot
      const dataUrl = canvas.toDataURL('image/png', 0.1);

      // Use the data URL however you need, for example display it in an img tag
      const img = document.createElement('img');
      img.src = dataUrl;
      document.body.appendChild(img);

    };

    const saveTheAdded = (psId, pris, forms, listDs, setT) => {
      // save_poster 
      let posterName = psId + '_' + JSON.parse(window.localStorage.getItem("athlete_id"))
      addItem(
          {
            'id': psId, 'price': pris, 'name': forms, 'posterName': posterName,
            'quantity': 1, 'listdata': listDs, 'settings': setT
          }
        )
        
        // let styles = window.getComputedStyle(element);
        // let styleString = '';
        // for (let prop of styles) {
        //     if (styles[prop]) {
        //         styleString += `${prop}: ${styles[prop]}; `;
        //     }
        // }

        // // Get outer HTML of the element
        // let html = element.outerHTML;

        // // Inject inline styles into the outer HTML and return
        // let htmlWithStyles = html.replace('>', ` style='${styleString}'>`);
        let theItem = getItem(psId)
        if (!theItem){
          console.log("first time")
          let john = document.getElementById('poster').outerHTML
          let matches = john.matchAll(svgIdPattern)
          let usedIds = [];
          for (let match of matches) {
            // match[1] will contain the group within the parentheses, i.e. the id number
            usedIds.push(match[1]);
          }

          const response = fetch(`${API_BASE_URL}/save_poster`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({'athlete_id': JSON.parse(window.localStorage.getItem("athlete_id")), 'data': usedIds, 'posterName': posterName})
          })}
    }

    let currentPosterss = getCurrentPosterId()
    const getImage = () => takeScreenshot(reportTemplateRef.current)

    const [opendrawer, setOpendrawer] = useState(false)
    return (
        <div>
            {notifyText && <CustomizedSnackbars text={notifyText} severe='warning'/>}
            <div className='johnny'></div>
            <Grid container justifyContent='center'>
            {/* <Row key='first_row' style={{marginLeft:'0', marginRight:'0'}}> */}
              <Grid item={true} className='gotTheGrid' xs={12} sm={12} md={7}>
              <div className='larsknap' style={{display: showConfig ? 'block' : 'none'}}>
                <DataTable id='configKnap' useRows={svgData} deleteData={props.deleteData} setNum={setNum} listData={listData} settings={settings} triggerSettingUpdate={triggerSettingUpdate} setSvgData={setSvgData} setListData={setListData}/>
              </div>
              {/* <Col key='poster_column' style={{paddingLeft: '0', paddingRight: '0', marginLeft:'0', marginRight:'0'}}> */}
                <div className='poster' id='poster' ref={reportTemplateRef}>
                    {isLoading === false && 
                      // <div className='watermark-container'>
                        renderPoster()
                      //   <div className='watermark'></div>
                      // </div>
                    }
                    {isLoading && <Loader/>}
                </div>
              {/* </Col> */}
              </Grid>
              <Grid item={true} sm={0.5} md={1.5}></Grid>
              {/* <Col key='settings_column'> */}
              <Grid item={true} xs={12} sm={12} md={3.5} component={Box} display={{ xs: 'none', md: 'block' }}>
                <Col key='settings_control' style={{maxWidth: '250px', minWidth: '250px'}}>

                  <CategorySettings 
                    categories={listData} 
                    handleDragEnd={handleDragEnd} 
                    ChangeCount={ChangeCount} 
                    ChangeColor={ChangeColor}
                    ChangeCheck={ChangeCheck}
                    isSortable={isSortable} 
                    setIsSortable={setIsSortable}
                    extraPrefix='og'
                    key='categorySettings'
                  />


                  <FormControl key='formForFormat'
                    sx={{ m: 1, border: '1px solid darkgrey' }} size='small'
                  >
                    <InputLabel id='demo-simple-select-label' key='labelForm' style={{ fontFamily: 'monReg'}}>Størrelse</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={format}
                      label='Plakatstørrelse'
                      onChange={handleFormatChange}
                      key='selectForm'
                      style={{ fontFamily: 'monReg'}}
                    >
                      <MenuItem value={'A4'} key='a4' style={{ fontFamily: 'monReg'}}>A4 (210x300) - {prices["A4"]} kr.</MenuItem>
                      <MenuItem value={'B2'} key='b2' style={{ fontFamily: 'monReg'}}>B2 (500x700) - {prices["B2"]} kr.</MenuItem>
                      <MenuItem value={'B1'} key='b1' style={{ fontFamily: 'monReg'}}>B1 (700x1000) - {prices["B1"]} kr.</MenuItem>
                    </Select>
                  </FormControl>
                  <div key={'posterId_' + currentPosterss}>
                    <Button 
                    // style={btStyles.button}
                    id='buyKnap'
                    // onClick={() => addItem(
                    //   'quantity': 1, 'listdata': JSON.stringify(listData), 'settings': JSON.stringify(settings)})}>
                    onClick={() => saveTheAdded('posterId_' + currentPosterss, prices[format], format, JSON.stringify(listData), JSON.stringify(settings))}
                    >
                        Tilføj til kurv
                    </Button>
                  </div>
                </Col>
              </Grid>
              {/* </Col> */}
            {/* </Row> */}
            <SettingsButton setOpenDrawer={setOpendrawer}
                saveTheAdded={saveTheAdded}                 
                listData={listData} 
                settings={settings}
                currentPosterss={currentPosterss}
                prices={prices}
                format={format}
              />

              <DrawerSettings 
                saveTheAdded={saveTheAdded}
                listData={listData} 
                open={opendrawer} 
                setOpen={setOpendrawer}
                handleDragEnd={handleDragEnd} 
                ChangeCount={ChangeCount} 
                ChangeColor={ChangeColor}
                ChangeCheck={ChangeCheck}
                format={format}
                handleFormatChange={handleFormatChange}
                settings={settings}
                currentPosterss={currentPosterss}
                prices={prices}
                setFormat={setFormat}
                isSortable={isSortable} 
                setIsSortable={setIsSortable}
              />
              
              {/* <Grid
                  component={Box}
                  // className={classes.gridItem}
                  item
                  xs={3}
                  display={{ xs: 'block', md: 'none' }}
                >
                <Button id='radikal' onClick={() => setOpendrawer(true)}>opendrawer</Button>
               </Grid> */}

            </Grid>
            </div>
        )
    }

export default Poster
