import Header from '../components/Header/header.js'
import Poster from '../components/Poster/poster.js'
import DrawerSettings from '../components/Drawer/drawer.js'
import React, { useState, Fragment, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Loader from '../components/Loader/loader.js';
import { useLocation } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataTable from '../components/DataTable/datatable.js';
import CustomizedSnackbars from '../components/Alert/alert.js';
import { useRef } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useCart } from 'react-use-cart';
import Tooltip from '@mui/material/Tooltip';


const Studio = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL
  // console.log("API_BASE_URL:", process.env.REACT_APP_API_BASE_URL);
  // console.log("DOMAIN_URL", DOMAIN_URL)

  const [loadFromStore, setLoadFromStore] = useState(false)
  const [loggedIn, changeLogin] = useState(false)
  const [provideMessage, setProvideMessage] = useState("")
  const [metaData, setMetaData] = useState([])
  const [userData, setUserData] = useState({})
  const [num, setNum] = useState(0)
  const location = useLocation()
  const [loadingNow, setLoadingNow] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [svgsStoredInLocals, setsvgsStoredInLocals] = useState(false)
  const [alertText, setAlertText] = useState("");
  const [happyText, setHappyText] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showConfig, setShowConfig] = useState(false)
  let { totalItems } = useCart()
  
  
  const checkIfSvgExists = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("poster");
  
      request.onerror = () => {
        reject(false);
      };
      
      request.onsuccess = () => {
        const db = request.result;
        
        if (!db.objectStoreNames.contains("svgs")) {
          resolve(false);
          return;
        }
  
        const tx = db.transaction("svgs", "readonly");
        const store = tx.objectStore("svgs");
  
        const countRequest = store.count();
        countRequest.onsuccess = () => resolve(countRequest.result > 0);
        countRequest.onerror = () => reject(false);
      };
  
      request.onupgradeneeded = () => {
        request.result.createObjectStore("svgs", { keyPath: "id", autoIncrement: true });
        resolve(false);
      };
  
      request.onerror = () => {
        reject(false);
      };
    });
  };
  

  const populateDB = (svgs, number) => {
    if (Object.keys(svgs).length === 0){
      return
    }
    let updated = false
    const openRequest = indexedDB.open('poster');

    openRequest.onupgradeneeded = () => {
      const db = openRequest.result;
      const store = db.createObjectStore('svgs', { keyPath: "id", autoIncrement: true })//, { keyPath: 'id', autoIncrement: true });
      store.createIndex('indicator', 'indicator', { unique: false });
      store.createIndex('color', 'color', { unique: false });
      store.createIndex('category', 'category', { unique: false });
      store.createIndex('strokeWidth', 'strokeWidth', { unique: false });
      store.createIndex('distance', 'distance', { unique: false });
      store.createIndex('date', 'date', { unique: false });
    };

    openRequest.onsuccess = () => {
      console.log("in ")
      const db = openRequest.result;
      const transaction = db.transaction('svgs', 'readonly');
      const objectStore = transaction.objectStore('svgs');

      objectStore.getAll().onsuccess = (event) => {
        const existingRecords = event.target.result;
        console.log(existingRecords.length, number)
        if (existingRecords.length !== number) {
          // If no records exist, populate the store 
          const writeTransaction = db.transaction('svgs', 'readwrite');
          const store = writeTransaction.objectStore('svgs');
          let indicators = existingRecords.map(row => row.indicator)
          var co = 0
          for (let category in svgs){
            for (let i = 0; i < svgs[category].length; i++){
              co += 1
              if (indicators.includes(svgs[category][i].indicator)){
                continue
              }
              store.put({
                "indicator": svgs[category][i].indicator,
                "d": svgs[category][i].d,
                "category": category,
                "color": svgs[category][i].color,
                "strokeWidth": svgs[category][i].strokeWidth,
                "distance": svgs[category][i].distance,
                "date": svgs[category][i].date
              })
            }
          }
          window.localStorage.setItem("savedSvgs", JSON.stringify(co))
        }
      }
    } 
    openRequest.onerror = () => {
      const savedItems =  JSON.parse(window.localStorage.getItem("storedSvgs"))
      if (!(savedItems) || savedItems.length !== number) {
        let indicators = savedItems ? savedItems.map(row => row.indicator) : []
        var co = 0
        const forSaving = []
        for (let category in svgs){
          for (let i = 0; i < svgs[category].length; i++){
            // if (indicators.includes(svgs[category][i].indicator)){
            //   continue
            // }
            co += 1
            forSaving.push({
              "indicator": svgs[category][i].indicator,
              "d": svgs[category][i].d,
              "category": category,
              "color": svgs[category][i].color,
              "strokeWidth": svgs[category][i].strokeWidth,
              "distance": svgs[category][i].distance,
              "date": svgs[category][i].date
            })
          }
        }

        window.localStorage.setItem("storedSvgs", JSON.stringify(forSaving))
        window.localStorage.setItem("savedSvgs", JSON.stringify(co))
        setsvgsStoredInLocals(true)
      }
    }
  }


  const removeAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    showAlert(false);
  };

  function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  function responseFromStrava(url){
    if (url){
      if (url.includes("code")){
        let query = parseQuery(url)
        if ("scope" in query){
          return query
        }
      }
    }
    return false
  }
  useEffect(() => {
    setAlertText(provideMessage)
  }, [provideMessage])
  // studio?state=&code=9e3e1671b14edb33fcad89dd3d7cca6c4526313c&scope=read,read_all,activity:read_all
  useEffect(() => {
      let isMounted = true;

      async function fetchStuff(url, param) {
        async function makeTheCall(ur, para) {
          try {
            const response = await fetch(`${API_BASE_URL}/${ur}/${para}`);
            const data = await response.json();
            return data
          } catch (error) {
            setAlertText("Hov, noget gik kaldt mens vi hentede data.")
            setShowAlert(true)
          }
        }
        let respon = await makeTheCall(url, param)
        return respon
      }

      if (loggedIn === false){
        if (window.localStorage.getItem("savedSvgs")){
          // todo something with checking the storage of svgs in the browser 
          window.localStorage.setItem("loggedIn", JSON.stringify(true))
          changeLogin(true)
          
          checkIfSvgExists()
          .then((hasPoster) => {
            if (hasPoster) {
              // do something if there are records
            } else {
              changeLogin(false)

              window.localStorage.removeItem("savedSvgs")
              window.localStorage.removeItem("loggedIn")
              window.localStorage.removeItem("settings")
              window.localStorage.removeItem("athlete_id")
              window.localStorage.removeItem("listData")
              window.localStorage.removeItem("storedSvgs")
              setAlertText("Du blev logget ind, men vi har intet data. Virker ikke i incognito/privat browsing.")
              // do something else if there are no records
            }
          })
          .catch((error) => {
            let alreadySaved = window.localStorage.getItem("savedSvgs")
            let alreadyStored = window.localStorage.getItem("storedSvgs")
            if (alreadySaved && alreadyStored){
              setLoadFromStore(true)
            } else {
              window.localStorage.removeItem("savedSvgs")
              window.localStorage.removeItem("loggedIn")
              window.localStorage.removeItem("settings")
              window.localStorage.removeItem("athlete_id")
              window.localStorage.removeItem("listData")
              window.localStorage.removeItem("storedSvgs")

              setAlertText("Du blev logget ind, men vi kan ikke bruge din data. Print-lap virker ikke i incognito/privat browsing.")
            }
          });

          return
        }
        if (JSON.parse(window.localStorage.getItem("loggedIn"))){
          if (window.localStorage.getItem("savedSvgs")){
            // todo something with checking the storage of svgs in the browser 
            window.localStorage.setItem("loggedIn", JSON.stringify(true))
            changeLogin(true)
            return
          }
        }
        // check om der er data i browseren
        // check om brugeren er logget ind

        // få data 
        let fromStrava = responseFromStrava(location.search)
        if (fromStrava !== false & loadingNow === false){

          setLoadingNow(true)
          // check om brugeren eksisterer
          fetchStuff("does_user_exist", fromStrava.code).then(
            (userRespons) => {
              if (isMounted) {
                setUserData(userRespons.data)
                setMetaData(userRespons.meta)
                window.localStorage.setItem("loggedIn", JSON.stringify(true))
                window.localStorage.setItem("athlete_id", JSON.stringify(userRespons.athlete_id))
                window.localStorage.setItem("listData", JSON.stringify(userRespons.meta))
                window.localStorage.setItem("settings", JSON.stringify(userRespons.settings))
                populateDB(userRespons.data, userRespons["number"])
                setNum(userRespons["number"])
                setLoadingNow(false)
                changeLogin(true)
              }
            }
            )

            
            // hvis den gør, hent fra get_data
            // hvis ikke, hent fra nyt endpoint

          }
      }
    return () => {
      isMounted = false
    }

    }, [location]
  )
    // check if data exist
  function dropTable() {
      const request = indexedDB.open("poster");
      request.onsuccess = () => {
        const db = request.result;
        const objectStore = db.transaction('svgs', "readwrite").objectStore('svgs');
        const objectStoreRequest = objectStore.clear();
    
        objectStoreRequest.onsuccess = function(event) {
        }
      };
    }
  // collect new data 
  const anyNewData = () => {
    async function pull(){
      const response = await fetch(`${API_BASE_URL}/pull_new_data/${window.localStorage.getItem("athlete_id")}`);
      const data = await response.json();
      if (data.meta.length === 0){
      }
      return data
    }
    setLoadingNow(true)
    changeLogin(false)
    setHappyText("Henter data")
    pull().then(
      (pulledData) => {
        if (pulledData.meta.length === 0){
          setAlertText("")
          window.localStorage.removeItem("loggedIn")
          window.localStorage.removeItem("athlete_id")
          window.localStorage.removeItem("listData")
          window.localStorage.removeItem("settings")
          window.localStorage.removeItem("savedSvgs")
          setLoadingNow(false)
          setAlertText("Bruger er ikke kendt. Hvis du har data på Strava, prøv at logge ind igen.")
        } else {
          if (pulledData.new_count === parseInt(window.localStorage.getItem("savedSvgs"))){
            setLoadingNow(false)
            changeLogin(true)
            setHappyText("Der blev ikke hentet noget nyt data")
          } else if (pulledData.new_count > parseInt(window.localStorage.getItem("savedSvgs"))){
            window.localStorage.setItem("listData", JSON.stringify(pulledData.meta))
            window.localStorage.setItem("settings", JSON.stringify(pulledData.settings))
            window.localStorage.setItem("savedSvgs", JSON.stringify(pulledData.new_count))
            populateDB(pulledData.data, parseInt(pulledData.new_count))
            setNum(pulledData.new_count)
            setUserData(pulledData.data)
            setMetaData(pulledData.meta)
            setLoadingNow(false)
            changeLogin(true)
            setHappyText("Ny data hentet")
          }
        }
      }
    )
    
  }

  const deleteData = () => {
    async function deleteIt(){
      const response = await fetch(`${API_BASE_URL}/delete_data/${JSON.parse(window.localStorage.getItem("athlete_id"))}`);
      const data = await response.json()
      return data
    }
    setLoadingNow(true)
    changeLogin(false)
    deleteIt().then(
      (deletedMessage) => {
        if (deletedMessage.deleted){
          dropTable()
          setHappyText("")
          window.localStorage.removeItem("loggedIn")
          window.localStorage.removeItem("athlete_id")
          window.localStorage.removeItem("listData")
          window.localStorage.removeItem("settings")
          window.localStorage.removeItem("savedSvgs")
          window.localStorage.removeItem("storedSvgs")
          setDialogOpen(false)
          setHappyText(deletedMessage.message)
          setLoadingNow(false)
        } else {
          setLoadingNow(false)
          changeLogin(true)
          setAlertText("")
          setAlertText(deletedMessage.message)
        }
      }
    )
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  
  
  // setUserData(userRespons.data)
  // setMetaData(userRespons.meta)
  // window.localStorage.setItem("loggedIn", JSON.stringify(true))
  // window.localStorage.setItem("athlete_id", JSON.stringify(userRespons.athlete_id))
  // window.localStorage.setItem("listData", JSON.stringify(userRespons.meta))
  // window.localStorage.setItem("settings", JSON.stringify(userRespons.settings))
  // populateDB(userRespons.data, userRespons["number"])
  // setNum(userRespons["number"])
  // setLoadingNow(false)

  return (
    <Fragment>
      <div>
        {alertText && <CustomizedSnackbars text={alertText} severe="error"/>}
        {happyText && <CustomizedSnackbars text={happyText} severe="success"/>}
      </div>
      <Grid container>
        {/* <Grid item xs={0} sm={1}/> */}
          <Grid item xs={12} sm={12}>
            <Header />
            <Grid container justifyContent="center" spacing={0} marginTop="60px" maxWidth="1600px" marginLeft="auto" marginRight="auto">
              {loggedIn === false && loadingNow === false &&
               <Grid item={true} xs={11} sm={7} md={5}>
                  <a href={`https://www.strava.com/oauth/authorize?client_id=59599&redirect_uri=${DOMAIN_URL}/studio&response_type=code&scope=read,read_all,activity:read_all`}>
                  <div className="shad" style={{marginTop:"30px"}}>
                      <h3>Kom i gang ved at forbinde til din Strava konto!</h3>
                      <center><div className="connect-button">Forbind til STRAVA</div></center>
                  </div>
                  </a>
                </Grid>
              }
              {loggedIn && 
              <>
              <Grid item={true} xs={11} sm={10} textAlign="center">
                <h3>Design din plakat her.</h3>
                <p>Vi har {window.localStorage.getItem("savedSvgs") || num} af dine aktiviteter 
                    <SettingsIcon 
                      fontSize="small" 
                      onMouseOver={(e) => {e.target.style.cursor = 'pointer'}}
                      onClick={() => {
                        const showB = document.querySelector('.showButtons')
                        showB.style.display = showB.style.display === 'none' ? 'block' : 'none'
                        setShowConfig(showB.style.display === 'none' ? false : true)
                      }}
                      />
                  </p>
                <div className="showButtons" style={{display: "none"}}>
                  <p>
                    <Tooltip
                        title={totalItems > 0 ? "Du kan kun hente data, hvis kurven er tom" : ""}>
                      <span>
                        <Button 
                          disabled={totalItems > 0 ? true : false}
                          id="theUpdateButton"
                          onClick={anyNewData}>Hent ny data</Button>
                        </span>
                      </Tooltip>
                    <Tooltip
                      title={totalItems > 0 ? "Du kan kun slette data, hvis kurven er tom" : ""}>
                    <span>
                      <Button 
                        disabled={totalItems > 0 ? true : false}
                        id="theDeleteButton"
                        onClick={handleDialogOpen}>Slet data</Button>
                    </span>
                    </Tooltip>
                  </p>
                </div>
              </Grid>
              <Poster showConfig={showConfig} deleteData={deleteData} setNum={setNum} actualData={userData} loadFromStore={loadFromStore} setProvideMessage={setProvideMessage} changeLogin={changeLogin}/></>}
              
              {loadingNow && <Loader/>}
              <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                id="theDeleteDialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Er du sikker på du vil slette din data?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="success">
                    Nej
                  </Button>
                  <Button onClick={deleteData} color="secondary" autoFocus>
                    Ja, slet!
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        {/* <Grid item xs={0} sm={1}/> */}
        <DrawerSettings/>
      </Grid>
      {/* {alertText && <CustomizedSnackbars text={alertText} />} */}
    </Fragment>
  );
};

export default Studio;
