import React from 'react'
import { Link } from "react-router-dom";
import favicon from './favicon.png'
import './header.css'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Row, Col } from 'react-bootstrap'
import BasicModal from '../ShoppingCart/modal';
import Button from '@mui/material/Button';
import { useCart } from 'react-use-cart'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from "react-router-dom";


function Header () {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {totalItems} = useCart();
    const navigate = useNavigate();

    return ( 
      <Grid container marginTop="20px" marginBottom="20px" alignItems="center" maxWidth="1300px" marginLeft="auto" marginRight="auto" paddingRight="20px" paddingLeft="20px">
        <Grid item xs={12} sm={6}>
          <div className="header">
            <div id="logo">
            <Link to='/' style={{textDecoration: "inherit", color: "inherit"}}>
               {/* onClick=""> */}
              <span className="logo1">P</span>
              <span className="logo2">R</span>
              <span className="logo3">I</span>
              <span className="logo4">N</span>
              <span className="logo5">T</span>-
              <span className="logo6">L</span>
              <span className="logo7">A</span>
              <span className="logo8">P</span>
              <img src={favicon}/>
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="navigation">
            <nav>
              {/* <Link to='/'><>Home</></Link> |{" "} */}
              <Link to='/studio'><span className='studiolink' style={{fontFamily:"monFat !important"}}>Studio</span></Link>
              {/* <Link to='/studio'><span className='produktlink'>Produkter</span></Link> */}

              {/* <Link to='/'><>Home</></Link> |{" "}
              <Link to='/studio'><>Studio</></Link> |{" "}
              <Link to='/'><>Home</></Link> |{" "}
              <Link to='/studio'><>Studio</></Link>  */}
              <Button id="kurvknap" onClick={handleOpen}>
                <ShoppingCartIcon/> 
                <span>Kurv [{totalItems}]</span>
                </Button>
              <BasicModal open={open} handleClose={handleClose} setOpen={setOpen} navigate={navigate}/>
            </nav>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
          <Divider/>
        </Grid> */}
        {/* <Divider width="100%"/> */}
      </Grid>
    );
  }
export default Header