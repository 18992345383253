import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import './checkoutform.css'
import { useCart } from 'react-use-cart'
import { 
  TextField, 
  Button, 
  Grid,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DawaAutoComplete from '../AutoComplete/DawaAutoComplete';


function countDigits(str) {
    const regex = /\d/g;
    const matches = str.match(regex);
    return matches ? matches.length : 0;
  }

export default function CheckoutForm(props) {
  const {
    totalItems,
  } = useCart()
  const [postalCode, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [mobile, setMobile] = useState("")
  const [fornavn, setFornavn] = useState("")
  const [mail, setMail] = useState("")
  const [efternavn, setEfternavn] = useState("")
  const [address, setAddress] = useState("")
  const [validated, setValidated] = useState(false)
  
  const handleNext = props.handleNext
  const setLeveringsDetaljer = props.setLeveringsDetaljer
  const leveringsDetaljer = props.leveringsDetaljer

  const [isChecked, setIsChecked] = React.useState(false);

  useEffect(() => {
    if (Object.keys(leveringsDetaljer).length === 0 && leveringsDetaljer.constructor === Object){
    } else {
        setAddress(leveringsDetaljer.address)
        setPostalCode(leveringsDetaljer.postalCode)
        setCity(leveringsDetaljer.city)
        setMobile(leveringsDetaljer.mobile)
        setFornavn(leveringsDetaljer.fornavn)
        setMail(leveringsDetaljer.mail)
        setEfternavn(leveringsDetaljer.efternavn)
    }
  }, [])

  useEffect(() => {
    if (postalCode && city && mobile && fornavn && isChecked && address && mail && efternavn){
        const regex = /\d/g;
        const postalmatch = postalCode.match(regex);
        let postalokay = (postalmatch ? postalmatch.length : 0) === 4
        let mobileokay = (mobile ? mobile.length : 0) > 0
        let fornavnokay = (fornavn ? fornavn.length : 0) > 0
        let efternavnokay = (efternavn ? efternavn.length : 0) > 0
        let addressokay = (address ? address.length : 0) > 0
        let cityokay = (city ? city.length : 0) > 0
        let mailokay = (mail ? mail.length : 0) > 0
        let itemsokay = (totalItems ? totalItems : 0) > 0
        if (mailokay){
            const mailregex = /.+\@.+\..{2,}/g
            mailokay = mail.match(mailregex) ? true : false
        }
        if (itemsokay && isChecked && postalokay && mobileokay && fornavnokay && efternavnokay && addressokay && cityokay && mailokay){
            setLeveringsDetaljer({
                "isChecked": true,
                "postalCode": postalCode,
                "city": city,
                "mail": mail,
                "address": address,
                "fornavn": fornavn,
                "efternavn": efternavn,
                "mobile": mobile,
                "athlete_id": JSON.parse(window.localStorage.getItem("athlete_id"))
            })
            setValidated(true)
        } else {
            setValidated(false)
        }
    } else {
        setValidated(false)
    }
    
  }, [postalCode, city, mobile, fornavn, isChecked, address, mail, efternavn])

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted!');
  };

  function removePunctuation(str) {
    return str.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
  }

  function updateRest(e, option){
    if (option){
        let address = option.split(",")
        let by = address[address.length - 1];
        
        if (/\d{4,}\s\w+/.test(by)) {
            let spi = by.split(" ");
            const postnummer = spi[1];
            const bynavn = by.split(/[\d{4,}]/).join(" ").trim();
            setAddress(option)
            setPostalCode(postnummer)
            setCity(bynavn)
        }
    }
  }


  return (
        <form id="formCheck">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <TextField
                required
                // id="firstName"
                id="textField"
                label="Fornavn"
                name="firstName"
                autoComplete="given-name"
                fontFamily="monReg"
                size="small"
                
                value={fornavn}
                onChange={(event) => setFornavn(event.target.value)}
                // className={classes.textField}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                required
                // id="lastName"
                id="textField"
                label="Efternavn"
                name="lastName"
                autoComplete="family-name"
                size="small"
                value={efternavn}
                
                // className={classes.textField}
                onChange={(event) => setEfternavn(event.target.value)}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                // id="email"
                id="textField"
                
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                size="small"
                value={mail}
                onChange={(event) => setMail(event.target.value)}
                // className={classes.textField}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                // id="phone"
                id="textField"
                label="Mobilnummer"
                name="phone"
                type="tel"
                pattern="[0-9]{8}"
                
                autoComplete="tel"
                size="small"
                value={mobile}
                onChange={(event) => setMobile(event.target.value)}
                // className={classes.textField}
            />
            </Grid>

            <Grid item xs={12}>
                <DawaAutoComplete>
                    {({ value, suggestions, handleChange }) => {
                        let onlyStrings = suggestions ? suggestions.map((sugg) => sugg.tekst) : [];
                        return (
                        <Autocomplete
                            noOptionsText="Indtast kendt adresse"
                            // id="addressinput"
                            id="textField"
                            onChange={updateRest}
                            options={onlyStrings}
                            size="small"
                            value={address}
                            
                            // className={classes.textField}
                            renderInput={(params) => {
                                return <TextField required {...params} value={value} onChange={handleChange} label="Adresse" 
                                />
                            }}
                            filterOptions={(options, { inputValue }) =>
                                options.filter((option) =>
                                    removePunctuation(option.toLowerCase()).includes(
                                    removePunctuation(inputValue.toLowerCase())
                                    )
                                )
                            }
                        />
                        );
                    }}
                </DawaAutoComplete>

            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                disabled
                // id="postalCode"
                id="textField"
                label="Postnummer"
                name="postalCode"
                size="small"
                autoComplete="shipping postal-code"
                type="tel"
                pattern="[0-9]{3,4}"
                
                // className={classes.textField}
                value={postalCode}
                onChange={(event) => setPostalCode(event.target.value)}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                disabled
                // id="city"
                id="textField"
                label="By"
                name="city"
                
                size="small"
                autoComplete="shipping address-level2"
                // className={classes.textField}
                value={city}
                onChange={(event) => setPostalCode(event.target.value)}
            />
            </Grid>
            <Grid item xs={12}>
            <FormControlLabel
                control={
                <Checkbox
                    required
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    name="acceptTerms"
                    color="primary"
                />
                }
                style={{fontFamily: "monReg"}}
                id="textField"
                label="Jeg accepterer betingelserne og vilkårene"
            /> 
            <Link target="_blank" href="/terms.pdf" id="termsLink">
                Betingelse og vilkår
            </Link>
            </Grid>
        </Grid>
        <Button
            variant="contained"
            color="primary"
            id="confirmKnap"
            // className={classes.button}
            disabled={!validated}
            onClick={handleNext}
            style={{fontFamily: "monFat"}}
        >
            Bekræft
        </Button>
        </form>
  );
}
